@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TjARc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1Mu52xP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51S7ABc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TzBhc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOjCnqEu92Fr1Mu51TLBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmYUtvAw.ttf) format('truetype');
}
.autosave {
  padding-right: 20px;
  font-size: 12px;
  color: #19bcac;
}
.loader {
  animation: spin 1s infinite linear;
  border: solid 1vmin transparent;
  border-radius: 50%;
  border-right-color: #19bcac;
  border-top-color: #19bcac;
  box-sizing: border-box;
  height: 10vmin;
  left: calc((50vw - 5vmin) + 130px);
  position: fixed;
  top: calc(50% - 5vmin);
  width: 10vmin;
  opacity: 0.7;
  z-index: 1;
}
.loader:before {
  animation: spin 2s infinite linear;
  border: solid 1vmin transparent;
  border-radius: 50%;
  opacity: 1;
  border-right-color: #08867b;
  border-top-color: #08867b;
  box-sizing: border-box;
  content: "";
  height: 8vmin;
  left: 0;
  position: absolute;
  top: 0;
  width: 8vmin;
}
.loader:after {
  animation: spin 3s infinite linear;
  border: solid 1vmin transparent;
  border-radius: 50%;
  border-right-color: #18191a;
  border-top-color: #18191a;
  box-sizing: border-box;
  content: "";
  height: 6vmin;
  opacity: 1;
  left: 1vmin;
  position: absolute;
  top: 1vmin;
  width: 6vmin;
}
/**===== bars6 =====*/
#bars6 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
}
#bars6 span {
  position: absolute;
  display: block;
  bottom: 10px;
  width: 9px;
  height: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-animation: bars6 1.5s infinite ease-in-out;
  animation: bars6 1.5s infinite ease-in-out;
}
#bars6 span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
#bars6 span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
#bars6 span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
#bars6 span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
@keyframes bars6 {
  0% {
    height: 5px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #19bcac;
  }
  50% {
    height: 5px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
  100% {
    height: 5px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
}
@-webkit-keyframes bars6 {
  0% {
    height: 5px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #19bcac;
  }
  50% {
    height: 5px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
  100% {
    height: 5px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background: rgba(0, 0, 0, 0.25);
  }
}
/** END of bars6 */
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.server-log pre {
  padding: 5px 10px;
  background: #eee;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  height: 50vh;
  overflow: scroll;
}
.merge-table {
  box-shadow: 0px 2px 5px rgb(0 0 0%);
  border-radius: 10px;
}
.merge-table .merge-table-row .merge-row-item {
  width: 25%;
  display: inline-block;
  padding: 10px 20px;
}
.merge-table .merge-table-row .merge-row-item.mini-col {
  width: 100px;
  text-align: center;
}
.merge-table .merge-table-row .merge-row-item.mini-col.mini-col-large {
  width: 60px;
}
.merge-table .merge-table-row.merge-table-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 700;
}
.circle-plan-button-page .plan .row-name {
  padding: 15px 32px;
}
.circle-plan-button-page .plan .btn:not(.dropdown-toggle).btn-primary {
  background: #eee;
  border-radius: 100%;
  width: 48px !important;
  height: 48px;
  padding: 5px !important;
}
.circle-plan-button-page .plan .btn:not(.dropdown-toggle).btn-primary svg {
  fill: #666 !important;
  color: #666 !important;
}
.circle-plan-button-page .plan .btn:not(.dropdown-toggle).btn-primary:hover {
  background: #dfdfdf;
}
.circle-plan-button-page .plan .btn:not(.dropdown-toggle).btn-primary:hover svg {
  fill: #333 !important;
  color: #333 !important;
}
.admin-users-page .feis-page-scroller {
  width: calc(100% + 20px) !important;
  margin: -20px -30px !important;
  margin-left: 0px !important;
  height: calc(100vh - 166px) !important;
}
.no-width {
  width: 40px !important;
  display: inline-block;
}
.tab-table .tab-table-double-checked {
  position: relative;
  top: 4px;
  left: 10px;
}
.tab-table .tab-table-double-checked-span {
  position: relative;
  bottom: 10px;
}
.tab-table .tab-table-signedoff {
  left: 5px;
}
.tab-table .tab-table-signoff-left {
  width: 50%;
  float: left;
}
.tab-table .tab-table-signoff-right {
  width: 50%;
  float: right;
}
.mini-update-row {
  cursor: pointer;
}
.mini-update-row .row-content {
  height: 20px;
  position: relative;
}
.mini-update-row .row-content::after {
  top: 0px;
  right: 0px !important;
}
.feis-page .school-profile.profile-page,
.feis-page .page-container-slim {
  max-width: 1000px;
  margin: auto;
}
.note-container {
  margin: 10px;
  box-shadow: 0 2px 2px rgb(0 0 0%);
  border-radius: 5px;
  background: #fff;
  height: 100px;
  overflow: hidden;
  transition: all 0.3s;
  opacity: 0.9 !important;
}
.note-container:hover {
  box-shadow: 0px 2px 5px rgba(0 0 0%);
  opacity: 1 !important;
}
.note-container .note-icon-container {
  float: left;
  width: 100px;
  text-align: center;
}
.note-container .note-icon-container svg {
  margin-top: 20px;
  height: 48px;
  width: 48px;
}
.note-container .note-icon-container .notification-image {
  height: 100px;
  width: 100px;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
}
.note-container .note-content-container {
  font-size: 12px;
  padding: 10px;
  color: #888;
  cursor: pointer;
  margin-left: 110px;
}
.note-container .note-content-container .note-title {
  font-weight: 700;
  font-size: 14px;
}
.note-container .note-content-container .note-timestamp {
  margin-top: 10px;
  color: #18bbab;
  font-weight: 600;
}
.mini-tag {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 2px;
  display: inline-block;
}
.mini-tag .mini-tag-image {
  display: inline-block;
  height: 32px;
  width: 32px;
  border: none;
  margin-right: 0px;
  margin-top: 0px;
}
.mini-tag .mini-tag-title {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
}
.version-text {
  opacity: 0.7;
  font-size: 11px;
}
.negative-20-pad {
  margin: -20px !important;
}
.profile-right {
  float: right;
  width: 300px;
  text-align: right;
}
.profile-right .info-area {
  list-style: none;
  padding: 0px;
  text-align: left;
  box-shadow: 0px 2px 4px rgb(0 0 0%);
  padding-bottom: 30px;
  border-radius: 10px;
}
.profile-right .info-area li {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  padding-bottom: 6px;
}
.profile-right .info-area li label b {
  color: #333;
}
.profile-left {
  width: calc(100% - 300px);
  padding-right: 20px;
}
.highcharts-background {
  background: transparent;
  fill: none;
}
.price-container {
  margin-top: 0px !important;
}
.price-container .price {
  font-size: 22px;
  font-weight: 800;
  color: #19bcac;
}
.highcharts-container {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.highcharts-credits {
  display: none;
}
.highcharts-color-0 {
  fill: #19bcac;
  color: #19bcac;
}
.highcharts-point {
  fill: #19bcac;
}
.highcharts-graph {
  stroke: #19bcac;
}
.print-only {
  display: none;
}
@media print {
  .print-only {
    display: block;
  }
  thead {
    display: table-row-group;
  }
  .print-logo {
    height: 50px;
  }
  .print-hide {
    display: none !important;
  }
}
.replay {
  border-radius: 10px;
  overflow: hidden;
  background: transparent;
  background-color: none !important;
  border: none;
  box-shadow: 0px 2px 5px rgb(0 0 0%);
}
.replay .replay-slider-handle {
  background-color: #19bcac;
}
.replay .replay-controls-bar {
  backdrop-filter: blur(5px) !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
body {
  overflow-x: hidden;
  background: #f0f2f5;
  font-family: Roboto;
}
.feisfwd-leader-scroll {
  margin-top: -10px;
}
.feisfwd-leader-scroll .ScrollbarsCustom-Content {
  padding-bottom: 20px !important;
  padding-top: 10px !important;
}
.scorecard-label {
  margin-bottom: 0.56rem;
}
#cat-click-1 {
  min-height: 500px;
}
.circle-tile {
  display: inline-block;
  width: 240px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.4s;
}
.circle-tile:hover .tile-container {
  box-shadow: 0 5px 10px rgb(0 0 0%);
  margin-right: 0px;
}
.circle-tile:hover .tile-content h4 {
  color: #19bcac;
}
.circle-tile:hover .tile-content h5 {
  color: #19bcac;
}
.circle-tile .tile-container {
  display: inline-block;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 10px solid #fff;
  box-shadow: 0 2px 3px rgb(0 0 0%);
  transition: all 0.4s;
  margin-right: 0px;
}
.circle-tile .tile-container svg {
  margin-top: 60px;
}
.circle-tile .tile-content h4 {
  font-size: 1.2rem;
  transition: color 0.3s;
}
.circle-tile .tile-content h5 {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.3s;
}
.score-popover {
  width: 400px;
}
.score-popover ul.list-group {
  border: none !important;
  box-shadow: none !important;
}
.score-modal-list label {
  font-weight: 700;
}
.tag {
  display: inline-block;
  padding: 5px 20px;
  background: #eee;
  border-radius: 20px;
  margin-right: 10px;
}
.inline-chart-container {
  display: inline-block;
}
.inline-chart-container .inline-chart {
  max-height: 360px;
  width: 260px;
  display: inline-grid;
}
.square-tile {
  display: inline-block;
  width: 222px;
  height: 250px;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 0.4s;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.square-tile .tile-fallback-background {
  background: #19bcac;
}
.square-tile .tile-background {
  position: absolute;
  filter: blur(10px);
  background-size: 260px !important;
  width: 150%;
  height: 150px;
  top: -20px;
  left: -20px;
}
.square-tile:hover {
  box-shadow: 0 5px 10px rgb(0 0 0%);
}
.square-tile:hover .tile-container {
  margin-right: 0px;
}
.square-tile:hover .tile-content h4 {
  color: #19bcac;
}
.square-tile:hover .tile-content h5 {
  color: #19bcac;
}
.square-tile .avatar {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 50px;
  border: 3px solid #fff;
  box-shadow: 0 2px 3px rgb(0 0 0%);
  transition: all 0.4s;
  margin-right: 0px;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
}
.square-tile .avatar svg {
  margin-top: 40px;
  color: rgba(255, 255, 255, 0.8);
}
.square-tile .tile-content {
  position: absolute;
  top: 100px;
  padding-top: 70px;
  width: 100%;
  background: #fff;
  margin-bottom: 30px;
}
.square-tile .tile-content h4 {
  font-size: 1.2rem;
  transition: color 0.3s;
}
.square-tile .tile-content h5 {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.3s;
}
.exports-section {
  position: relative;
  display: block;
}
.exports-section.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.exports-section .single-export {
  width: calc(20% - 10px);
  max-width: 300px;
  display: inline-grid;
  margin: 0px 5px;
  box-shadow: 0px 2px 5px rgb(0 0 0%);
  border-radius: 5px;
}
.exports-section .single-export .export-header {
  display: block;
  padding: 20px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}
.exports-section .single-export .export-header h4 {
  font-size: 18px;
  color: #555;
}
.exports-section .single-export .export-body {
  display: block;
  padding: 10px;
}
.exports-section .single-export .export-body h4 {
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  color: #aaa;
}
.exports-section .single-export .export-body .export-list {
  list-style: none;
  padding-left: 0px;
}
.exports-section .single-export .export-body .export-list li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.exports-section .single-export .export-body .export-list li:last-child {
  border-bottom: none;
}
.pie-container {
  max-width: 100px;
  margin: auto;
  position: relative;
}
.pie-container.report-page {
  max-width: initial;
  padding: 20px;
  cursor: pointer;
}
.pie-container h3 {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 50px);
  color: #19bcac;
  width: 100px;
  text-align: center;
}
.pie-container h5 {
  position: absolute;
  top: calc(50% + 30px);
  left: calc(50% - 50px);
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100px;
  text-align: center;
}
.btn.btn-search {
  position: absolute;
  top: 25px;
  right: 20px;
  padding: 9px;
  height: 38px;
  padding: 0px;
  width: 39px;
  padding-left: 18px;
}
.tab-content.tabulator-content {
  padding-left: 0px;
  padding-right: 0px ;
}
.row.tabulator-table-pad {
  padding-left: 15px;
  padding-right: 15px;
}
.dancer-column.rounded {
  float: left;
  width: 400px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  margin-top: 250px;
}
.type-tile {
  background: #fff;
  display: inline-block;
  width: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 200px;
  color: #555;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
}
.type-tile:hover {
  color: #19bcac;
}
.type-tile.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.type-tile h2 {
  font-size: 22px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  width: 100%;
}
.type-tile h4 {
  font-size: 16px;
  text-transform: uppercase;
}
.type-tile h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #14a914;
}
.competition-tile {
  background: #fff;
  display: inline-grid;
  width: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 220px;
  color: #555;
  cursor: pointer;
  transition: color 0.3s;
}
.competition-tile:hover {
  color: #19bcac;
}
.competition-tile.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.competition-tile h1 {
  margin-top: 30px;
}
.competition-tile h4 {
  font-size: 16px;
  text-transform: uppercase;
}
.competition-tile h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #14a914;
}
.color-0 {
  background: #f7aaaa;
}
.form-control.form-control-inline,
.select-all-container {
  display: inline-block !important;
}
.select-all-container {
  margin-top: -6px;
}
.color-1 {
  background: #c68abd;
}
.color-2 {
  background: #b4e3f2;
}
.color-3 {
  background: #faf493;
}
.color-4 {
  background: #fab48c;
}
.color-5 {
  background: #cfd9cf;
}
.color-6 {
  background: #afd472;
}
.color-7 {
  background: #dcb171;
}
.color-8 {
  background: #8192c9;
}
.color-9 {
  background: #83cebf;
}
.color-10 {
  background: #f7a750;
}
.color-11 {
  background: #d98355;
}
.color-12 {
  background: #ee4f9d;
}
.color-13 {
  background: #f0aa1e;
}
.color-14 {
  background: #3399cc;
}
.color-15 {
  background: #999966;
}
.color-16 {
  background: #ff3333;
}
.progress-button {
  position: absolute;
  top: calc(50% + 80px);
  left: calc(50% - 200px);
  font-size: 32px;
  width: 400px;
  text-align: center;
  height: 48px;
  color: #0bb51f;
  z-index: 999999;
}
.margin-neg-20 {
  margin: -20px !important;
  width: calc(100% + 40px) !important;
}
.mini-page {
  max-width: 600px;
  margin: auto;
}
.standard-page {
  max-width: 900px;
  margin: auto;
}
.judge-config-tables .table-container {
  overflow-x: scroll;
}
.judge-config-tables .table th {
  min-width: 200px !important;
}
.judge-config-tables .form-control.form-control-mini {
  text-align: left;
  padding-left: 20px;
}
.judge-config-tables .form-check .form-check-label {
  font-weight: 500;
}
.judge-config-tables .form-check.row-label .form-check-label,
.judge-config-tables .form-check.column-label .form-check-label,
.judge-config-tables .form-check.table-label .form-check-label {
  font-weight: 800;
}
.tabulator-table-pad .form-check .form-check-input {
  height: 40px;
  width: 40px;
}
.grid-tile {
  width: 200px;
  height: 200px;
  text-align: center;
  box-shadow: 0px 2px 5px rgb(0 0 0%);
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.grid-tile:hover svg {
  fill: #19bcac;
  color: #19bcac;
}
.grid-tile:hover h3 {
  color: #19bcac;
}
.grid-tile svg {
  margin-top: 50px;
  fill: #2c3e48;
  color: #2c3e48;
  transition: all 0.3s;
}
.grid-tile h3 {
  position: absolute;
  width: 100%;
  bottom: 20px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #2c3e48;
  transition: all 0.3s;
  padding: 0px 10px;
}
.modal {
  z-index: 99999999 !important;
}
.form-check .form-check-label {
  text-transform: capitalize;
}
.note-btn svg {
  fill: rgba(255, 255, 255, 0.9) !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
.note-btn svg.active {
  fill: rgba(255, 255, 255, 0.9) !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
.note-scroller {
  height: 500px !important;
  max-height: calc(100vh - 100px) !important;
}
.animated.step1 {
  animation-delay: 0.2s;
}
.animated.step2 {
  animation-delay: 0.4s;
}
.animated.step3 {
  animation-delay: 0.6s;
}
.animated.step4 {
  animation-delay: 0.8s;
}
.animated.step5 {
  animation-delay: 1s;
}
.animated.step6 {
  animation-delay: 1.2s;
}
.animated.step7 {
  animation-delay: 1.4s;
}
.animated.step8 {
  animation-delay: 1.6s;
}
.update-right.text-right {
  margin-top: 15px;
  padding-right: 30px;
}
.text-danger {
  fill: #dc3545 !important;
  color: #dc3545 !important;
}
.text-danger svg {
  fill: #dc3545 !important;
  color: #dc3545 !important;
}
.notification-menu {
  margin: 0px;
  width: 500px !important;
  padding: 0px !important;
}
.round-tile {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.round-tile h4 {
  font-size: 32px;
  color: #666;
}
.btn:focus {
  box-shadow: none !important;
}
.btn.btn-secondary svg {
  fill: #fff;
  color: #fff;
  margin-top: -5px;
}
.btn.btn-flat svg {
  margin-top: -5px;
}
.btn.btn-flat:hover {
  background: rgba(0, 0, 0, 0.1);
}
.notification-container {
  margin-top: 80px;
  width: 400px;
}
.notification-container .notification {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}
.form-control.hidden-file-input {
  position: absolute;
  top: 0;
  height: 230px;
  width: 200px;
  opacity: 0 !important;
}
.form-control.hidden-file-input.centered-input {
  position: absolute;
  padding-left: 50%;
  top: 30px;
  width: 100%;
}
.video-tile {
  width: 500px;
  margin: auto;
  display: block;
  max-width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;
}
.ex-pad {
  padding: 20px 20px;
}
.spacer {
  height: 20px;
}
.spacer-mini {
  height: 10px;
}
.elevated {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.no-data {
  color: #999;
  text-align: center;
  display: block;
  width: 100%;
}
.rounded {
  border-radius: 10px !important;
  overflow: hidden;
}
.rounded.show-overflow {
  overflow: initial;
}
.tab-row {
  position: relative;
}
.tab-row.items-4 a {
  width: calc(100% / 4);
}
.tab-row.items-4 .tab-highlight {
  width: calc(100% / 4);
}
.tab-row.items-4 .tab-0.active ~ .tab-highlight {
  left: 0px;
  transition: all 0.3s ease;
}
.tab-row.items-4 .tab-1.active ~ .tab-highlight {
  left: 25%;
  transition: all 0.3s ease;
}
.tab-row.items-4 .tab-2.active ~ .tab-highlight {
  left: 50%;
  transition: all 0.3s ease;
}
.tab-row.items-4 .tab-3.active ~ .tab-highlight {
  left: 75%;
}
.tab-row.items-4 .tab-4.active ~ .tab-highlight {
  left: 100%;
}
.tab-row.items-5 a {
  width: calc(100% / 5);
}
.tab-row.items-5 .tab-highlight {
  width: calc(100% / 5);
}
.tab-row.items-5 .tab-0.active ~ .tab-highlight {
  left: 0px;
  transition: all 0.3s ease;
}
.tab-row.items-5 .tab-1.active ~ .tab-highlight {
  left: 20%;
  transition: all 0.3s ease;
}
.tab-row.items-5 .tab-2.active ~ .tab-highlight {
  left: 40%;
  transition: all 0.3s ease;
}
.tab-row.items-5 .tab-3.active ~ .tab-highlight {
  left: 60%;
}
.tab-row.items-5 .tab-4.active ~ .tab-highlight {
  left: 80%;
}
.tab-row.items-5 .tab-5.active ~ .tab-highlight {
  left: 100%;
}
.tab-row.items-6 a {
  width: calc(100% / 6);
}
.tab-row.items-6 .tab-highlight {
  width: calc(100% / 6);
}
.tab-row.items-6 .tab-0.active ~ .tab-highlight {
  left: 0px;
  transition: all 0.3s ease;
}
.tab-row.items-6 .tab-1.active ~ .tab-highlight {
  left: 16.67%;
  transition: all 0.3s ease;
}
.tab-row.items-6 .tab-2.active ~ .tab-highlight {
  left: 33.33%;
  transition: all 0.3s ease;
}
.tab-row.items-6 .tab-3.active ~ .tab-highlight {
  left: 50%;
}
.tab-row.items-6 .tab-4.active ~ .tab-highlight {
  left: 66.67%;
}
.tab-row.items-6 .tab-5.active ~ .tab-highlight {
  left: 83.33%;
}
.tab-row.items-6 .tab-6.active ~ .tab-highlight {
  left: 100%;
}
.tab-row.items-7 a {
  width: calc(100% / 7);
}
.tab-row.items-7 .tab-highlight {
  width: calc(100% / 7);
}
.tab-row.items-7 .tab-0.active ~ .tab-highlight {
  left: 0px;
  transition: all 0.3s ease;
}
.tab-row.items-7 .tab-1.active ~ .tab-highlight {
  left: 14.28%;
  transition: all 0.3s ease;
}
.tab-row.items-7 .tab-2.active ~ .tab-highlight {
  left: 28.57%;
  transition: all 0.3s ease;
}
.tab-row.items-7 .tab-3.active ~ .tab-highlight {
  left: 42.86%;
}
.tab-row.items-7 .tab-4.active ~ .tab-highlight {
  left: 57.14%;
}
.tab-row.items-7 .tab-5.active ~ .tab-highlight {
  left: 71.43%;
}
.tab-row.items-7 .tab-6.active ~ .tab-highlight {
  left: 85.71%;
}
.tab-row.items-7 .tab-7.active ~ .tab-highlight {
  left: 100%;
}
.tab-row.items-8 a {
  width: calc(100% / 8);
}
.tab-row.items-8 .tab-highlight {
  width: calc(100% / 8);
}
.tab-row.items-8 .tab-0.active ~ .tab-highlight {
  left: 0px;
  transition: all 0.3s ease;
}
.tab-row.items-8 .tab-1.active ~ .tab-highlight {
  left: 12.5%;
  transition: all 0.3s ease;
}
.tab-row.items-8 .tab-2.active ~ .tab-highlight {
  left: 25%;
  transition: all 0.3s ease;
}
.tab-row.items-8 .tab-3.active ~ .tab-highlight {
  left: 37.5%;
}
.tab-row.items-8 .tab-4.active ~ .tab-highlight {
  left: 50%;
}
.tab-row.items-8 .tab-5.active ~ .tab-highlight {
  left: 62.5%;
}
.tab-row.items-8 .tab-6.active ~ .tab-highlight {
  left: 75%;
}
.tab-row.items-8 .tab-7.active ~ .tab-highlight {
  left: 87.5%;
}
.tab-row.items-8 .tab-8.active ~ .tab-highlight {
  left: 100%;
}
.tab-row a {
  width: calc(100% / 3);
  text-align: center;
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.3);
  padding: 15px;
}
.tab-row a.active {
  color: #08867b;
  background-color: #19bcac;
  background-image: linear-gradient(45deg, #08867b, #19bcac);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.tab-highlight {
  position: absolute;
  background: linear-gradient(to right, #08867b, #19bcac) fixed;
  height: 3px;
  width: calc(100% / 3);
  left: 0px;
  display: block;
  transition: all 0.3s ease;
}
.tab-highlight-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.05);
}
.tab-0.active ~ .tab-highlight {
  left: 0px;
  transition: all 0.3s ease;
}
.tab-1.active ~ .tab-highlight {
  left: 33.33%;
  transition: all 0.3s ease;
}
.tab-2.active ~ .tab-highlight {
  left: 66.66%;
  transition: all 0.3s ease;
}
.tab-3.active ~ .tab-highlight {
  left: 100%;
}
.tab-4.active ~ .tab-highlight {
  left: 100%;
}
.no-notes {
  line-height: 100px;
  text-align: center;
}
.onboarding-complete {
  padding: 20px;
  color: #666;
}
.onboarding-complete h4 {
  text-align: center;
  color: #666;
  margin: 50px;
}
.form-back-with-title {
  padding-top: 10px;
}
.form-back-with-title .btn.btn-link {
  padding-top: 0px;
  margin-top: -2px;
  float: left;
}
.form-back-with-title h4 {
  display: inline-block;
  font-size: 16px;
}
.feis-page-onboarding {
  background: #fff;
  max-width: 700px;
  margin: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.form-preview-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}
.row-details .detail-value {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}
.row-details label {
  margin: 0px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  width: 100%;
  margin-left: 0px !important;
}
.onboarding-container {
  padding: 30px 20px;
  padding-top: 15px;
  transition: opacity 0.3s;
}
.onboarding-container.loading {
  opacity: 0.4;
  pointer-events: none;
}
.loading {
  opacity: 0.2;
  pointer-events: none;
}
.loading.field-container {
  opacity: 0.2;
}
.npr {
  padding-right: 0px !important;
}
.auth-hint {
  margin-bottom: 20px;
  margin-top: 25px;
  font-size: 12px;
}
.auth-title {
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.prevent-transform {
  transform: translate(0px, 0px) !important;
}
.entry-title {
  min-height: 50px;
}
.entry-title .event-title {
  line-height: 50px;
  font-size: 24px;
  margin-left: 30px;
}
.section {
  padding: 40px 15px;
}
.section .section-title {
  font-size: 16px;
  margin-bottom: 20px;
  color: #aaa;
}
.section .section-title svg {
  fill: #aaa;
  color: #aaa;
}
.credit-card-container {
  width: 300px;
  display: inline-block;
  float: none;
  margin-right: 20px;
  margin-botom: 20px;
}
.credit-card-container .credit-card {
  height: 180px;
  background: #eee;
  width: 300px;
  border-radius: 10px;
  padding-top: 100px;
  margin-bottom: 20px;
}
.credit-card-container .credit-card .number {
  padding-left: 30px;
}
.credit-card-container .credit-card .card-logo {
  height: 50px;
  float: right;
  margin-right: 20px;
}
.championship-cell {
  position: relative;
}
.championship-cell .championship-colors {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.championship-cell .championship-colors .championship-color {
  width: 33%;
  position: absolute;
  height: 100%;
}
.championship-cell .championship-colors .championship-color:nth-child(2) {
  left: 33.33%;
}
.championship-cell .championship-colors .championship-color:nth-child(3) {
  left: 66.66%;
}
.footer {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-bottom: 40px;
  font-size: 0.8125rem;
  letter-spacing: 0.06rem;
  position: relative;
}
.footer a {
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.3s;
}
.footer a:hover {
  text-decoration: none;
  color: #19bcac;
}
.tile-container {
  height: 300px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  background-blend-mode: overlay;
  margin-bottom: 50px;
  cursor: pointer;
  transition: all 0.4s;
  width: 310px;
  position: relative;
  margin-right: 20px;
}
.tile-container .tile-delete {
  position: absolute;
  bottom: 0;
  margin-bottom: -40px;
  text-align: center;
  display: block;
  width: 100%;
}
.tile-container:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}
.tile-container .btn-flat.floating-edit-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  margin: 0px;
  z-index: 999;
  height: 42px;
  width: 42px;
  padding: 5px;
  border-radius: 100%;
  margin: 5px;
}
.tile-container .btn-flat.floating-edit-button svg {
  margin: 0px !important;
  color: rgba(255, 255, 255, 0.7);
  fill: rgba(255, 255, 255, 0.7);
}
.tile-container .tile-button {
  position: absolute;
  bottom: 130px;
  margin: auto;
  display: block;
  text-align: center;
  width: calc(100% - 30px);
}
.tile-container .more {
  color: #ffffff;
  font-size: 22px;
}
.tile-container svg {
  fill: #fff;
  color: #fff;
  margin-top: 110px;
}
.tile-container .tile-gradient {
  position: absolute;
  width: calc(100% - 0px);
  height: 300px;
  display: inline;
  border-radius: 10px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6));
}
.tile-container .tile-content {
  position: absolute;
  bottom: 0px;
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  padding: 10px 15px;
  padding-right: 32px;
}
.tile-container .tile-content h4 {
  font-size: 16px;
  margin-bottom: 0px;
}
.tile-container .tile-content h5 {
  font-size: 16px;
}
.feis-player {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(15px);
  height: 60px;
  transition: all 0.5s;
  transform: translate(0px, 0px);
}
.feis-player.closed {
  transform: translate(0px, 60px);
}
.feis-player .player-controls .btn-close-player {
  position: fixed;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  width: 45px;
  border-radius: 100%;
}
.feis-player .player-controls .btn-close-player::before {
  content: '';
  position: absolute;
  height: 45px;
  width: 45px;
  background: rgba(255, 255, 255, 0.2);
  margin-left: -11px;
  margin-top: -8px;
  border-radius: 100%;
  transform: scale(0);
  transition: all 0.2s;
}
.feis-player .player-controls .btn-close-player:hover::before {
  transform: scale(1);
}
.feis-player .player-controls .btn-close-player svg {
  fill: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
}
.feis-player .player-controls .progress-bar-container {
  width: calc(100% - 140px);
  height: 20px;
  margin-top: 0;
  position: fixed;
  bottom: 22px;
  padding-top: 10px;
  display: inline-block;
  cursor: pointer;
}
.feis-player .player-controls .progress-bar-container .seeker {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background: linear-gradient(to right, #08867b, #19bcac);
  background-attachment: fixed !important;
  position: absolute;
  margin-top: -4px;
  transition: left, transform 0.5s;
  margin-left: -5px;
  transform: scale(0);
  cursor: pointer;
  pointer-events: none;
}
.feis-player .player-controls .progress-bar-container:hover .seeker,
.feis-player .player-controls .progress-bar-container.isSeeking .seeker {
  transform: scale(1.5);
}
.feis-player .player-controls .progress-bar-container:hover .progress-bar .progress,
.feis-player .player-controls .progress-bar-container.isSeeking .progress-bar .progress {
  transition: 0s;
}
.feis-player .player-controls .progress-bar-container .progress-bar {
  display: block;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  height: 3px;
}
.feis-player .player-controls .progress-bar-container .progress-bar .progress {
  transition: width 0.5s;
  background: linear-gradient(to right, #08867b, #19bcac);
  background-attachment: fixed !important;
  width: 20%;
}
.feis-player .player-controls .btn.btn-primary {
  margin: 10px;
  padding: 10px;
  width: 45px;
  height: 45px;
  padding-left: 22px;
  padding-top: 8px;
  background: rgba(255, 255, 255, 0.9);
  margin-top: 7px;
}
.feis-player .player-controls .btn.btn-primary::before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  width: 45px;
  height: 45px;
  margin-left: -22px;
  margin-top: -9px;
  border-radius: 100%;
  transition: all 0.4s;
  transform: scale(0);
}
.feis-player .player-controls .btn.btn-primary:hover {
  background: rgba(255, 255, 255, 0.9);
}
.feis-player .player-controls .btn.btn-primary:hover::before {
  transform: scale(1);
}
.feis-player .player-controls .btn.btn-primary svg {
  fill: rgba(0, 0, 0, 0.7) !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.show-sm {
  display: none;
}
.btn.btn-small-padding {
  padding: 8px 20px !important;
}
.react-tagsinput {
  background: rgba(0, 0, 0, 0.05);
  height: 42px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20px;
  width: 100%;
  border: 0px;
  padding-left: 10px;
}
.react-tagsinput .react-tagsinput-tag {
  padding: 5px 15px;
  border-radius: 20px;
  color: rgba(255, 255, 255.5);
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  background: #19bcac;
}
.object-picker-menu-alt .dropdown-menu {
  width: 560px;
}
.object-picker-menu,
.react-autosuggest__suggestions-container {
  transform: scale(1);
  transition: all 0.5s;
  left: initial !important;
  top: initial !important;
  min-width: 220px;
  padding: 2px 0px;
  position: absolute !important;
  overflow: auto;
  max-height: initial !important;
  margin-left: initial !important;
  width: calc(100% - 30px);
  margin-top: 5px !important;
  border-radius: 10px;
  z-index: 999999999;
  background: #fff !important;
  transform: scale(0);
  transition: all 0.3s;
  opacity: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.object-picker-menu .dropdown-menu,
.react-autosuggest__suggestions-container .dropdown-menu {
  width: 560px;
}
.object-picker-menu .ScrollbarsCustom-Content,
.react-autosuggest__suggestions-container .ScrollbarsCustom-Content {
  padding: 0px !important;
}
.object-picker-menu .react-autosuggest__suggestions-list,
.react-autosuggest__suggestions-container .react-autosuggest__suggestions-list {
  margin: 0px;
  list-style: none;
  padding-left: 0px;
}
.object-picker-menu.open,
.react-autosuggest__suggestions-container.open,
.object-picker-menu.react-autosuggest__suggestions-container--open,
.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  transform: scale(1);
  opacity: 1;
}
.object-picker-menu .object-picker-menu-item,
.react-autosuggest__suggestions-container .object-picker-menu-item {
  font-size: 1rem;
  display: block;
  width: 100%;
  padding: 0.8rem 0;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.object-picker-menu .object-picker-menu-item::before,
.react-autosuggest__suggestions-container .object-picker-menu-item::before {
  content: '';
  position: absolute;
  transform: scale(0);
  height: 100%;
  width: 100%;
  margin-left: -62px;
  max-height: 52px;
  margin-top: -15px;
  border-radius: 100px;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0.05);
}
.object-picker-menu .object-picker-menu-item.hovered::before,
.react-autosuggest__suggestions-container .object-picker-menu-item.hovered::before {
  position: absolute;
  transform: scale(1);
  border-radius: 0px;
  transition: all 0.2s;
}
.object-picker-menu .object-picker-menu-item:hover::before,
.react-autosuggest__suggestions-container .object-picker-menu-item:hover::before {
  position: absolute;
  transform: scale(1);
  border-radius: 0px;
  transition: all 0.2s;
}
.object-picker-menu .object-picker-menu-item .object-menu-item-image,
.react-autosuggest__suggestions-container .object-picker-menu-item .object-menu-item-image {
  height: 32px;
  width: 32px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 100%;
  display: inline-block;
  float: left;
  margin-top: -4px;
  margin-left: 15px;
  margin-right: 15px;
}
.object-picker-menu .object-picker-menu-item .object-menu-item-label,
.react-autosuggest__suggestions-container .object-picker-menu-item .object-menu-item-label {
  display: inline-block;
}
.subscription-track-row {
  margin-left: 0px;
}
.subscription-track-row .col-3 {
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.subscription-track-row .col-3 .sub-track-label {
  font-size: 11px;
  font-weight: 700;
  padding-top: 5px;
  padding-left: 0px;
}
.subscription-header {
  margin-left: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.subscription-header .subscription-header-row {
  margin: auto;
  width: 16px;
}
.track-subscriptions .permission {
  cursor: pointer;
  height: 26px;
  width: 26px;
  padding: 3px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.04);
  margin: auto;
}
.track-subscriptions .permission::before,
.track-subscriptions .permission::after {
  transition: all 0.3s;
  background: #e24040;
}
.track-subscriptions .permission::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 2px;
  transform: rotate(45deg) translate3d(0px, -2px, 0px);
  margin-top: 10px;
}
.track-subscriptions .permission::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 2px;
  transform: rotate(-45deg) translate3d(2px, 0px, 0px);
  margin-top: 10px;
}
.track-subscriptions .permission.perm-true::before,
.track-subscriptions .permission.perm-true::after {
  background: #38a738;
}
.track-subscriptions .permission.perm-true::before {
  content: '';
  position: absolute;
  width: 16px;
  transform: rotate(132deg) translate3d(-4px, 2px, 20px);
  margin-top: 12px;
  margin-left: 2px;
}
.track-subscriptions .permission.perm-true::after {
  content: '';
  position: absolute;
  transform: rotate(50deg);
  width: 7px;
  margin-top: 11px;
  margin-left: 1px;
}
.object-picker-container-top label {
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 700;
  padding-left: 30px;
}
.object-picker-container-top .react-autosuggest__container {
  display: block !important;
}
.object-picker-container-top .object-picker-container {
  background: rgba(0, 0, 0, 0.05);
  height: 42px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
.object-picker-container-top .object-picker-container .btn.btn-flat {
  position: absolute;
  margin-top: -2px;
  /* margin-left: -32px; */
  padding: 6px 9px!important;
  border-radius: 100%;
  right: 20px;
}
.object-picker-container-top .object-picker-container .btn.btn-flat::before {
  content: '';
  position: absolute;
  transform: scale(0);
  height: 100%;
  width: 100%;
  margin-left: -9px;
  max-height: 52px;
  margin-top: -4px;
  border-radius: 100px;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0.05);
}
.object-picker-container-top .object-picker-container .btn.btn-flat svg {
  transition: fill 0.4s;
}
.object-picker-container-top .object-picker-container .btn.btn-flat:hover svg {
  fill: rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
}
.object-picker-container-top .object-picker-container .btn.btn-flat:hover::before {
  position: absolute;
  transform: scale(1);
  transition: all 0.2s;
}
.object-picker-container-top .object-picker-container .object-picker-image {
  display: inline-block;
  float: left;
}
.object-picker-container-top .object-picker-container .object-picker-image .object-image {
  height: 32px;
  width: 32px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 5px;
}
.object-picker-container-top .object-picker-container .object-picker-input {
  display: inline-block;
  width: calc(100% - 32px);
}
.object-picker-container-top .object-picker-container .object-picker-input input {
  outline: none !important;
  border: none !important;
  background: transparent !important;
  padding-top: 3px;
  padding-left: 15px;
  width: 100%;
}
.divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.05);
}
@media (max-width: 900px) {
  .onboarding-steps,
  .event-steps {
    margin: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: initial !important;
  }
  .onboarding-steps.event-steps,
  .event-steps.event-steps {
    margin-left: 0px;
  }
  .onboarding-steps .onboarding-step,
  .event-steps .onboarding-step,
  .onboarding-steps .event-step,
  .event-steps .event-step {
    width: initial !important;
    display: inline-block;
    text-align: center;
    background: transparent;
    padding: 10px;
    position: relative;
    border-top: 0px solid;
    border-bottom: 0px solid;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    height: 48px;
  }
  .onboarding-steps .onboarding-step:last-child::after,
  .event-steps .onboarding-step:last-child::after,
  .onboarding-steps .event-step:last-child::after,
  .event-steps .event-step:last-child::after {
    content: '';
    position: relative;
  }
  .onboarding-steps .onboarding-step.active,
  .event-steps .onboarding-step.active,
  .onboarding-steps .event-step.active,
  .event-steps .event-step.active {
    border-color: #08867b !important;
    color: #08867b;
    font-weight: 600;
  }
  .onboarding-steps .onboarding-step::after,
  .event-steps .onboarding-step::after,
  .onboarding-steps .event-step::after,
  .event-steps .event-step::after {
    content: ' >> ';
    position: relative;
  }
  .onboarding-steps .onboarding-step label,
  .event-steps .onboarding-step label,
  .onboarding-steps .event-step label,
  .event-steps .event-step label {
    margin-bottom: 0px;
  }
}
.onboarding-steps {
  margin-top: 120px;
}
.onboarding-steps.event-steps {
  margin-top: 30px;
}
@media (min-width: 900px) {
  .onboarding-steps,
  .event-steps {
    margin: 30px;
    margin-top: 120px;
    margin-bottom: 30px;
  }
  .onboarding-steps.event-steps,
  .event-steps.event-steps {
    margin-left: 0px;
    margin-top: 30px;
  }
  .onboarding-steps .onboarding-step,
  .event-steps .onboarding-step,
  .onboarding-steps .event-step,
  .event-steps .event-step {
    display: inline-block;
    text-align: center;
    background: #fff;
    padding: 10px;
    position: relative;
    border-top: 2px solid;
    border-bottom: 2px solid;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    height: 48px;
    line-height: 24px;
    border-color: #eee !important;
  }
  .onboarding-steps .onboarding-step.active,
  .event-steps .onboarding-step.active,
  .onboarding-steps .event-step.active,
  .event-steps .event-step.active {
    border-color: #08867b !important;
    color: #08867b;
    font-weight: 600;
  }
  .onboarding-steps .onboarding-step.active::after,
  .event-steps .onboarding-step.active::after,
  .onboarding-steps .event-step.active::after,
  .event-steps .event-step.active::after {
    border-color: #08867b;
  }
  .onboarding-steps .onboarding-step.active-right::after,
  .event-steps .onboarding-step.active-right::after,
  .onboarding-steps .event-step.active-right::after,
  .event-steps .event-step.active-right::after {
    border-color: #08867b;
  }
  .onboarding-steps .onboarding-step:nth-child(1),
  .event-steps .onboarding-step:nth-child(1),
  .onboarding-steps .event-step:nth-child(1),
  .event-steps .event-step:nth-child(1) {
    border-left: 2px solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .onboarding-steps .onboarding-step:nth-child(1).active,
  .event-steps .onboarding-step:nth-child(1).active,
  .onboarding-steps .event-step:nth-child(1).active,
  .event-steps .event-step:nth-child(1).active {
    border-color: #08867b !important;
  }
  .onboarding-steps .onboarding-step::after,
  .event-steps .onboarding-step::after,
  .onboarding-steps .event-step::after,
  .event-steps .event-step::after {
    content: '';
    position: absolute;
    height: 34px;
    border-right: 2px solid;
    border-bottom: 2px solid;
    width: 34px;
    transform: rotate(-45deg);
    right: -17px;
    background: #fff;
    margin-top: -5px;
    z-index: 999;
    border-color: #eee;
  }
  .onboarding-steps .onboarding-step label,
  .event-steps .onboarding-step label,
  .onboarding-steps .event-step label,
  .event-steps .event-step label {
    margin-bottom: 0px;
  }
}
@media (max-width: 990px) {
  .profile-right {
    float: none;
    width: 100%;
    text-align: center;
  }
  .profile-left {
    width: 100%;
  }
  .navbar .dropdown-menu {
    left: 0px;
    right: 0px;
    position: fixed;
    top: 83px;
    width: 100% !important;
  }
  .show-sm {
    display: block;
  }
  .navbar-nav {
    flex-direction: initial;
  }
  .sidebar {
    z-index: 2;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: all 0.5s;
    transition-delay: 0s;
  }
  .sidebar.closed {
    width: 0px !important;
  }
  .sidebar.open {
    opacity: 1;
    transition: opacity 0.5s;
    transition-delay: 0.2s;
  }
  .feis-page {
    margin-left: 10px !important;
    z-index: 10000;
    position: relative;
    transition-duration: 0.5s;
    width: calc(100% - 20px) !important;
    transform: translate3d(0px, 0px, 0px);
  }
  .feis-page .ScrollbarsCustom.re .ScrollbarsCustom-Content {
    padding: 0px !important;
  }
  .feis-page .theme-pretty .favorites-scroller {
    margin-left: 0px;
    margin-top: -94px !important;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background: #eee;
    margin-bottom: -20px;
    width: 260px;
    height: calc(100vh - 90px);
  }
  .feis-page .theme-pretty .favorites-scroller .ScrollbarsCustom-Wrapper {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
  .feis-page.player-open {
    height: calc(100vh - 190px);
    min-height: calc(100vh - 190px);
  }
  .feis-page.player-open .theme-pretty .favorites-scroller {
    margin-left: 0px;
    margin-top: -94px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background: #eee;
    margin-bottom: -20px;
    width: 260px;
    height: calc(100vh - 190px);
  }
  .feis-page.sidebar-open {
    overflow-y: hidden;
    width: calc(100% - 280px);
    transform: translate3d(260px, 0, 0);
  }
}
.overflow-hidden {
  overflow: hidden !important;
}
.react-autosuggest__container {
  display: inline-block !important;
}
.react-autosuggest__container .react-tagsinput-input {
  width: 120px;
}
.btn.btn-link {
  padding-left: 0px !important;
  box-shadow: none !important;
}
.color-picker {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  margin-bottom: 0px;
}
.color-picker.hide {
  max-height: 0;
}
.color-picker.show {
  max-height: 500px;
  margin-bottom: 20px;
}
.color-picker .color-picker-tile {
  padding: 20px 10px;
}
.draggable-category {
  display: inline-block;
}
.player-open .btn-action.fab-menu-toggle {
  bottom: 70px !important;
}
.btn-action.fab-menu-toggle {
  border: none !important;
  outline: none !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
  background: #ff784f !important;
}
.btn-action.fab-menu-toggle svg {
  transition: 0.5s;
}
.btn-action.fab-menu-toggle.toggled svg {
  transform: rotate(135deg);
}
.player-open .fab-menu {
  bottom: 120px;
}
.fab-menu {
  position: fixed;
  right: 20px;
  bottom: 80px;
  list-style: none;
  text-align: right;
  transition: 0.5s;
  animation-delay: 0s;
}
.fab-menu li {
  transform: scale(0);
}
.fab-menu.open li {
  transform: scale(1) translate3d(0px, 0px, 0px);
  opacity: 1;
}
.fab-menu li {
  margin-right: 15px;
  cursor: pointer;
  transform: translate3d(0px, 100px, 0px);
  opacity: 0;
  transition: 0.5s;
  animation-delay: 0s;
}
.fab-menu li.step1 {
  transition-delay: 0.2s;
}
.fab-menu li.step2 {
  transition-delay: 0.4s;
}
.fab-menu li.step3 {
  transition-delay: 0.6s;
}
.fab-menu li .fab-menu-label {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 40px;
  border-radius: 10px;
  padding-left: 20px;
  text-align: right;
  padding-right: 20px;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 15px;
}
.fab-menu li .fab-menu-icon {
  background: linear-gradient(to bottom right, #08867b, #19bcac) fixed;
  height: 48px;
  text-align: center;
  width: 48px;
  padding-top: 10px;
  border-radius: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  display: inline-block;
}
.category-tile {
  width: 200px;
  /* box-shadow: inset 3px -1px #eee; */
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  background-position: 70% 40%;
  background-attachment: fixed;
  border-radius: 15px;
  height: 160px;
  background-size: initial;
  background-blend-mode: overlay;
  overflow: hidden;
}
.category-tile .category-label {
  margin: 7px;
  height: 145px;
  padding-top: 30px;
  text-align: left;
  padding-left: 15px;
  border: 1px solid #fff;
  border-radius: 11px;
}
.category-tile .category-label .category-text {
  vertical-align: middle;
  display: inline-block;
  /* line-height: 0%; */
  margin-top: -11px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
}
.news-item-img {
  float: left;
  display: inline-block;
  background-position: center!important;
  background-size: cover!important;
  margin: -2px 15px 0px 0px;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 5px rgb(0 0 0%);
}
.news-item-img.img-circle {
  border-radius: 100%;
  /* margin: 0px; */
  height: 45px;
  width: 45px;
  margin-top: 0px;
  margin-left: 5px;
}
.field-container {
  margin-bottom: 20px;
  transition: opacity 0.4s;
}
.field-container.category-container {
  margin-bottom: 30px;
}
.field-container label {
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 700;
  color: #222 !important;
  padding-left: 0px;
  margin-left: 0px !important;
}
.ql-editor {
  min-height: 500px;
}
.form-image {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}
.small-avatar .artist-img.avatar {
  float: left;
  height: 32px;
  width: 32px;
  border-width: 1px !important;
  margin-right: 10px;
}
.dancer-name {
  padding-top: 4px;
}
.object-picker-input .object-picker-menu-item.dropdown-item {
  padding-left: 20px;
}
.object-picker-input .object-picker-menu-item.dropdown-item:hover {
  background: #eee;
}
.f21-tooltip {
  z-index: 99999999999 !important;
}
.notification-container .notification {
  border-radius: 5px !important;
  background: #eee;
  color: #333;
  border: 2px solid;
  padding-left: 100px;
}
.notification-container .notification::before {
  font-size: 42px;
  padding-left: 20px;
}
.notification-container .notification.notification-success {
  border-color: #19bcac;
}
.notification-container .notification.notification-success::before {
  color: #19bcac;
}
.notification-container .notification.notification-error {
  border-color: #db401d;
}
.notification-container .notification.notification-error::before {
  color: #db401d;
}
.video-upload {
  background: #eee;
  padding: 5px;
  border-radius: 5px;
}
.video-upload.schedule-upload {
  width: 500px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 0px;
}
.video-upload .video-upload-name {
  height: 42px;
  overflow: hidden;
  white-space: nowrap;
  padding: 9px;
  padding-left: 20px;
  cursor: pointer !important;
}
.video-upload .video-upload-name .btn.btn-flat {
  margin-top: -10px;
  padding: 0px;
  float: left;
  margin-top: 0px;
  padding: 5px 20px;
  margin-top: -7px;
  border: 2px solid #ddd;
  border-radius: 0px;
  cursor: pointer !important;
}
.video-upload .hide-video-input {
  opacity: 0;
  margin-top: -42px;
  position: absolute;
  z-index: 300;
  cursor: pointer !important;
}
.non-typing-input {
  outline: none !important;
  width: calc(100%);
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer!important;
  padding: 0!important;
  margin: 3px 0 0!important;
  text-align: left;
  border-radius: 0!important;
  padding-left: 20px !important;
}
.non-typing-input:focus,
.non-typing-input:active {
  outline: none !important;
  box-shadow: none !important;
}
.dancer-select-container .dancer-select {
  width: 200px;
  display: inline-block;
  margin-right: 30px;
  text-align: center;
}
.dancer-select-container .dancer-select .artist-img.avatar {
  height: 50px;
  width: 50px;
  margin: auto;
  border-width: 2px;
}
.dancer-select-container .dancer-select .dancer-name {
  text-align: center;
  margin: 10px;
}
.fee-container {
  margin-top: 10px;
  display: inline-block;
}
.btn.neg5 {
  margin-top: -20px;
}
table.table-full {
  width: 100%;
}
.table tbody tr.bg-grey {
  background: #eee;
}
.no-bottom-border td {
  border-bottom: none !important;
}
.radio-text {
  font-size: 12px;
  margin-top: 10px;
}
.chart-explainer {
  width: 170px;
  margin: auto;
  text-align: left;
  display: block;
}
.chart-explainer .explainer-row {
  margin: auto;
}
.chart-explainer .explainer-row .color {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  padding-top: 10px;
  margin-right: 5px;
}
.price-container {
  width: 100px;
  display: inline-block;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  margin-right: 10px;
}
.price-container.price-container-large {
  width: unset;
  display: inline-block;
  float: right;
  border-right: none;
}
.price-container .big-price {
  font-size: 22px;
  color: #2fb53f;
  font-weight: 600;
  line-height: 20px;
}
.price-container .big-price.fee {
  color: #5293b1;
}
.price-container .price-title {
  font-size: 12px;
  color: #555;
  text-align: center;
}
.form-buttons {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 61px;
}
.form-buttons.form-buttons-fixed {
  padding: 30px;
  margin-bottom: 0px;
}
.form-buttons .btn.btn-flat.secondary-button {
  color: #ff784f;
}
.form-buttons .btn.btn-flat.secondary-button svg {
  fill: #ff784f;
  color: #ff784f;
}
.form-button-wrapper {
  margin-top: 50px;
  position: relative;
  width: initial;
}
.form-right {
  width: calc(100% - 200px);
  padding: 0px 20px;
  display: inline-block;
  padding-right: 0px;
}
.text-cap {
  text-transform: capitalize !important;
}
.hint-text {
  padding: 0px 10px;
  font-size: 11px;
}
.no-padding {
  padding: 0px !important;
}
.notification {
  border-radius: 10px !important;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  margin: 20px 0px;
}
.draggable-item {
  padding: 10px;
  margin: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.transitioned {
  transition: background 0.5s linear;
  -moz-transition: background 0.5s linear;
  /* Firefox 4 */
  -webkit-transition: background 0.5s linear;
  /* Safari and Chrome */
  -o-transition: background 0.5s linear;
  /* Opera */
  -ms-transition: background 0.5s linear;
  /* Explorer 10 */
}
.retroBorder {
  border: 2px solid;
  border-bottom-color: #9f9f9f;
  border-top-color: #d0d0d0;
  border-left-color: #d2d2d2;
  border-right-color: #a5a5a5;
}
.action-buttons-right {
  padding-right: 32px;
  margin-top: 7px;
}
.btn.btn-slim {
  width: 34px !important;
  padding: 12px !important;
}
.btn.btn-slim svg {
  margin: 0px !important;
}
.dropzone-target {
  text-align: center;
  padding: 50px 0px;
  font-size: 22px;
  border: 3px dashed #ccc;
}
.file-content {
  width: calc(100% - 300px);
  margin-left: 260px;
}
.file-content .file-header {
  padding: 10px 20px;
  position: fixed;
  /* box-shadow: 0px 5px 12px; */
  /* overflow: hidden; */
  border-radius: 0px;
  border-top-right-radius: 20px;
  margin-right: -70px;
  margin: 0px -70px -20px -30px !important;
  border-bottom: 2px solid #ddd;
  background: rgba(0, 0, 0, 0.05);
  padding-bottom: 0;
  z-index: 4;
  margin-left: -30px;
  width: calc(100% - 500px);
}
.file-content .file-header .breadcrumb {
  background: transparent;
}
.file-content .file-view {
  padding-top: 20px;
}
.file-content .file-view.extra-file-view-padding {
  padding-top: 0px;
}
.file-view {
  height: calc(100vh - 130px);
}
.file-view .highlight .file-info {
  background: linear-gradient(to bottom right, #08867b, #19bcac);
  color: #fff;
}
.file-view .file-info {
  font-size: 1vw;
  text-align: center;
  border-radius: 15px;
  padding: 5px;
}
.file-view .file-info h3 {
  text-align: center;
  font-size: 1vw;
  margin: 0px;
  font-weight: 400;
  word-break: break-word;
}
.file-view .file-preview {
  padding: 0px;
}
.file-view .file-preview svg {
  fill: url(#svg-gradient) gray;
  color: url(#svg-gradient) gray;
}
.favorites-scroller {
  position: absolute !important;
  top: 20px;
}
.favorites-scroller .ScrollbarsCustom-Wrapper {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.modal.theme-pretty .dropzone,
.file-manager-form.theme-pretty .dropzone,
.modal.theme-light .dropzone,
.file-manager-form.theme-light .dropzone {
  outline: none !important;
}
.modal.theme-pretty:not(.theme-light) .file-preview svg,
.file-manager-form.theme-pretty:not(.theme-light) .file-preview svg,
.modal.theme-light:not(.theme-light) .file-preview svg,
.file-manager-form.theme-light:not(.theme-light) .file-preview svg {
  fill: url(#svg-gradient) gray;
  color: url(#svg-gradient) gray;
}
.modal.theme-pretty .file-preview,
.file-manager-form.theme-pretty .file-preview,
.modal.theme-light .file-preview,
.file-manager-form.theme-light .file-preview {
  height: 160px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 20px;
}
.modal.theme-pretty .file-preview.background-enabled,
.file-manager-form.theme-pretty .file-preview.background-enabled,
.modal.theme-light .file-preview.background-enabled,
.file-manager-form.theme-light .file-preview.background-enabled {
  border-radius: 10px;
  padding: 20px;
}
.modal.theme-pretty .file-preview svg,
.file-manager-form.theme-pretty .file-preview svg,
.modal.theme-light .file-preview svg,
.file-manager-form.theme-light .file-preview svg {
  margin: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 30px;
  margin-top: 0px;
  fill: #aaa;
  color: #aaa;
}
.modal.theme-pretty .file-info,
.file-manager-form.theme-pretty .file-info,
.modal.theme-light .file-info,
.file-manager-form.theme-light .file-info {
  padding: 10px;
  word-break: break-all !important;
}
.modal.theme-pretty .file-info h3,
.file-manager-form.theme-pretty .file-info h3,
.modal.theme-light .file-info h3,
.file-manager-form.theme-light .file-info h3 {
  font-size: 16px;
  margin-bottom: 0px;
}
.modal.theme-pretty .file-info .file-size,
.file-manager-form.theme-pretty .file-info .file-size,
.modal.theme-light .file-info .file-size,
.file-manager-form.theme-light .file-info .file-size {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}
.modal.theme-pretty .modal-content,
.file-manager-form.theme-pretty .modal-content,
.modal.theme-light .modal-content,
.file-manager-form.theme-light .modal-content {
  border-radius: 5px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.4);
}
.modal.theme-pretty .btn,
.file-manager-form.theme-pretty .btn,
.modal.theme-light .btn,
.file-manager-form.theme-light .btn {
  padding: 8px 30px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border: none;
  transition: background 0.5s linear;
  -moz-transition: background 0.5s linear;
  /* Firefox 4 */
  -webkit-transition: background 0.5s linear;
  /* Safari and Chrome */
  -o-transition: background 0.5s linear;
  /* Opera */
  -ms-transition: background 0.5s linear;
  /* Explorer 10 */
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.modal.theme-pretty .btn.btn-round,
.file-manager-form.theme-pretty .btn.btn-round,
.modal.theme-light .btn.btn-round,
.file-manager-form.theme-light .btn.btn-round {
  padding: 8px !important;
}
.modal.theme-pretty .btn.btn-action,
.file-manager-form.theme-pretty .btn.btn-action,
.modal.theme-light .btn.btn-action,
.file-manager-form.theme-light .btn.btn-action {
  position: fixed;
  bottom: 30px;
  right: 25px;
  height: 64px;
  width: 64px;
  border-radius: 100%;
  padding: 10px;
  padding-top: 7px;
  transition: all 0.5s;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}
.modal.theme-pretty .btn.btn-transparent,
.file-manager-form.theme-pretty .btn.btn-transparent,
.modal.theme-light .btn.btn-transparent,
.file-manager-form.theme-light .btn.btn-transparent {
  background: transparent;
  color: #aaa;
}
.modal.theme-pretty .btn.btn-transparent svg,
.file-manager-form.theme-pretty .btn.btn-transparent svg,
.modal.theme-light .btn.btn-transparent svg,
.file-manager-form.theme-light .btn.btn-transparent svg {
  fill: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  margin-left: -10px;
  margin-right: 10px;
}
.modal.theme-pretty .btn.btn-flat,
.file-manager-form.theme-pretty .btn.btn-flat,
.modal.theme-light .btn.btn-flat,
.file-manager-form.theme-light .btn.btn-flat {
  box-shadow: none;
}
.modal.theme-pretty .btn.btn-flat.dropdown-toggle,
.file-manager-form.theme-pretty .btn.btn-flat.dropdown-toggle,
.modal.theme-light .btn.btn-flat.dropdown-toggle,
.file-manager-form.theme-light .btn.btn-flat.dropdown-toggle {
  padding-left: 60px;
}
.modal.theme-pretty .btn.btn-flat.btn-round,
.file-manager-form.theme-pretty .btn.btn-flat.btn-round,
.modal.theme-light .btn.btn-flat.btn-round,
.file-manager-form.theme-light .btn.btn-flat.btn-round {
  padding: 8px !important;
}
.modal.theme-pretty .btn.btn-flat:hover,
.file-manager-form.theme-pretty .btn.btn-flat:hover,
.modal.theme-light .btn.btn-flat:hover,
.file-manager-form.theme-light .btn.btn-flat:hover,
.modal.theme-pretty .btn.btn-flat:active,
.file-manager-form.theme-pretty .btn.btn-flat:active,
.modal.theme-light .btn.btn-flat:active,
.file-manager-form.theme-light .btn.btn-flat:active {
  background: transparent !important;
  box-shadow: none !important;
  border: none;
}
.modal.theme-pretty .btn.btn-flat.dropdown-toggle::after,
.file-manager-form.theme-pretty .btn.btn-flat.dropdown-toggle::after,
.modal.theme-light .btn.btn-flat.dropdown-toggle::after,
.file-manager-form.theme-light .btn.btn-flat.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.modal.theme-pretty .btn.btn-danger:not(.btn-action) svg,
.file-manager-form.theme-pretty .btn.btn-danger:not(.btn-action) svg,
.modal.theme-light .btn.btn-danger:not(.btn-action) svg,
.file-manager-form.theme-light .btn.btn-danger:not(.btn-action) svg {
  fill: rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 0.9);
  margin-left: -10px;
  margin-right: 10px;
}
.modal.theme-pretty .btn.btn-primary,
.file-manager-form.theme-pretty .btn.btn-primary,
.modal.theme-light .btn.btn-primary,
.file-manager-form.theme-light .btn.btn-primary {
  background: #19bcac;
}
.modal.theme-pretty .btn.btn-primary:not(.btn-action) svg,
.file-manager-form.theme-pretty .btn.btn-primary:not(.btn-action) svg,
.modal.theme-light .btn.btn-primary:not(.btn-action) svg,
.file-manager-form.theme-light .btn.btn-primary:not(.btn-action) svg {
  fill: rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 0.9);
  margin-left: -10px;
  margin-right: 10px;
}
.modal.theme-pretty .btn.btn-primary:hover,
.file-manager-form.theme-pretty .btn.btn-primary:hover,
.modal.theme-light .btn.btn-primary:hover,
.file-manager-form.theme-light .btn.btn-primary:hover {
  background: #19bcac;
}
.modal.theme-pretty .btn:hover,
.file-manager-form.theme-pretty .btn:hover,
.modal.theme-light .btn:hover,
.file-manager-form.theme-light .btn:hover {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.5);
}
.header {
  text-align: center;
}
.theme-checked {
  position: absolute;
  margin-left: calc(50% - 52px);
  margin-top: 60px;
  opacity: 0.2;
}
.theme-name {
  text-transform: capitalize;
  text-align: center;
  margin-top: 10px;
  color: #666;
}
textarea.form-control {
  padding-top: 10px;
}
.tabulator-page-header {
  padding-left: 15px;
  padding-bottom: 15px;
}
.tabulator-page-header .checkbox-right {
  right: 40px;
}
.input-group .form-control {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.input-group .input-group-text {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border: none;
}
.form-upload-loader-container .progress-text {
  position: absolute;
  top: calc(50% + 40px);
  left: calc(50% - 200px);
  font-size: 32px;
  width: 400px;
  text-align: center;
  height: 48px;
  color: #0bb51f;
}
.form-upload-loader-container .progress-text-sm {
  position: absolute;
  top: calc(50% + 90px);
  left: calc(50% - 200px);
  font-size: 16px;
  width: 400px;
  text-align: center;
  height: 48px;
  color: #0bb51f;
  z-index: 1000;
  font-weight: 600;
}
.lds-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  transform: scale(0);
  transition: transform 0.3s;
  -webkit-transition: -moz-transform 0.3s ease-out;
  -moz-transition: -webkit-transform 0.3s ease-out;
}
.lds-ring.form-upload-loader {
  position: absolute;
}
.lds-ring.active {
  transform: scale(1);
  transition: transform 0.3s;
  -webkit-transition: -moz-transform 0.3s ease-out;
  -moz-transition: -webkit-transform 0.3s ease-out;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #08867b;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #08867b transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.form-control {
  background: rgba(0, 0, 0, 0.05);
  height: 42px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  width: 100%;
  border: 0px;
  padding-left: 30px;
}
.form-control:disabled {
  background: #ccc;
}
.form-control.slim {
  padding: 10px 15px;
  text-align: center;
  width: 70px;
}
.form-control.form-control-mini {
  padding: 5px;
  text-align: center;
  background: transparent;
}
.form-control.artist-title {
  position: absolute;
  bottom: 45px;
  left: 240px;
  width: calc(100% - 240px);
  background: transparent;
  color: #fff;
  border: none !important;
  font-size: 32px;
  outline: none !important;
}
.form-control.artist-title::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.mini-theme {
  height: 200px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.mini-theme .mini-sidebar {
  width: 70px;
  float: left;
  height: 100%;
}
.mini-theme .mini-sidebar .mini-sidebar-item {
  height: 10px;
  background: rgba(0, 0, 0, 0.1);
  margin: 5px;
  margin-left: 20px;
}
.mini-theme .mini-sidebar .mini-sidebar-item::before {
  display: block;
  content: '';
  position: absolute;
  width: 10px;
  background: rgba(0, 0, 0, 0.1);
  height: 10px;
  margin-left: -15px;
}
.mini-theme .mini-page {
  height: 150px;
  margin-left: 75px;
  margin-right: 10px;
  margin-top: 0px;
  padding: 5px;
}
.mini-theme .mini-page .mini-content {
  height: 50px;
  background: rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.mini-theme .mini-page .mini-title {
  height: 10px;
  width: 50px;
  background: rgba(0, 0, 0, 0.2);
  display: block;
}
.mini-theme .mini-header {
  height: 30px;
  width: 100%;
  padding-top: 2px;
}
.mini-theme .mini-header .mini-search {
  height: 15px;
  width: 80%;
  margin: auto;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 5px;
}
.mini-theme.mini-theme-light {
  background: #eee;
}
.mini-theme.mini-theme-light .mini-header {
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.mini-theme.mini-theme-pretty {
  background: linear-gradient(to bottom right, #08867b, #19bcac);
}
.mini-theme.mini-theme-pretty .mini-page {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.mini-theme.mini-theme-retro {
  background: #c0c0c0;
}
.mini-theme.mini-theme-retro .mini-header {
  border-bottom: 1px solid #9f9f9f;
}
.mini-theme.mini-theme-retro .mini-header .mini-search {
  border-radius: 0px;
  border: 1px solid;
  border-bottom-color: #9f9f9f;
  border-top-color: #d0d0d0;
  border-left-color: #d2d2d2;
  border-right-color: #a5a5a5;
  background: transparent;
}
.mini-theme.mini-theme-retro .mini-sidebar {
  border-right: 1px solid #a5a5a5;
  border-top: 1px solid #ccc;
}
.mini-theme.mini-theme-retro .mini-page {
  margin-left: 70px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.theme-retro.modal .modal-content {
  background: #c0c0c0;
  border-radius: 0px;
  border: 2px solid;
  border-bottom-color: #9f9f9f;
  border-top-color: #d0d0d0;
  border-left-color: #d2d2d2;
  border-right-color: #a5a5a5;
}
.theme-retro.modal .modal-content .dropzone {
  outline: none !important;
}
.theme-retro.modal .modal-content .file-preview {
  height: 160px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 0px;
  box-shadow: none;
  border: 2px solid;
  border-bottom-color: #9f9f9f;
  border-top-color: #d0d0d0;
  border-left-color: #d2d2d2;
  border-right-color: #a5a5a5;
}
.theme-retro.modal .modal-content .file-preview svg {
  margin: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 30px;
  margin-top: 0px;
  fill: #aaa;
  color: #aaa;
}
.theme-retro.modal .modal-content .file-info {
  padding: 10px;
}
.theme-retro.modal .modal-content .file-info h3 {
  font-size: 16px;
  margin-bottom: 0px;
  word-break: break-all;
}
.theme-retro.modal .modal-content .file-info .file-size {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}
.theme-retro.modal .modal-content .mini-theme {
  border-radius: 0px;
  border: 2px solid;
  border-bottom-color: #9f9f9f;
  border-top-color: #d0d0d0;
  border-left-color: #d2d2d2;
  border-right-color: #a5a5a5;
  box-shadow: none;
}
.theme-retro.modal .form-control {
  background: #c0c0c0;
  border: 2px solid;
  border-bottom-color: #9f9f9f;
  border-top-color: #d0d0d0;
  border-left-color: #d2d2d2;
  border-right-color: #a5a5a5;
  border-radius: 0px;
}
.theme-retro.modal .btn {
  background: #c0c0c0;
  border: 2px solid;
  border-bottom-color: #9f9f9f;
  border-top-color: #d0d0d0;
  border-left-color: #d2d2d2;
  border-right-color: #a5a5a5;
  color: #333;
  border-radius: 0px;
  box-shadow: none;
}
.feis-app {
  transition: background 0.3s;
}
.nav-form {
  width: calc(100% - 400px);
  margin: auto;
  display: block;
}
.navbar.navbar-light {
  background: transparent;
  padding: 15px;
  padding-right: 0px;
}
.navbar.navbar-light .dropdown .dropdown-toggle {
  padding-right: 10px;
}
.navbar.navbar-light .dropdown .dropdown-toggle:hover {
  background: transparent !important;
}
.navbar.navbar-light .dropdown .nav-item {
  width: calc(100% - 20px);
}
.navbar.navbar-light .dropdown .nav-item .menu-title {
  color: #aaa;
}
.navbar.navbar-light .dropdown .nav-item .nav-link {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 2px;
  height: 54px !important;
  padding: 15px 10px 15px 0 !important;
}
.navbar.navbar-light .dropdown .nav-item .nav-link::before {
  content: '';
  position: absolute;
  height: 54px !important;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: calc(100% - 20px);
  margin-top: -15px;
  transform: scale(0);
  transition: all 0.3s;
  opacity: 0;
  left: 10px;
  right: 10px;
}
.navbar.navbar-light .dropdown .nav-item .nav-link svg {
  fill: rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
  margin: -4px 20px 0 24px;
}
.navbar.navbar-light .dropdown .nav-item .nav-link:hover::before {
  transform: scale(1);
  opacity: 1;
}
.navbar.navbar-light .dropdown .nav-item.active .nav-link {
  color: #19bcac !important;
}
.navbar.navbar-light .dropdown .nav-item.active .nav-link svg {
  fill: #19bcac;
  color: #19bcac;
}
.navbar.navbar-light .dropdown .nav-item.active .nav-link::after {
  content: '';
  position: absolute;
  height: 54px !important;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: calc(100% - 20px);
  margin-top: -15px;
  transform: scale(1);
  transition: all 0.3s;
  opacity: 1;
  left: 10px;
  right: 10px;
}
.navbar.navbar-light .main-logo {
  height: 32px;
  margin: 0px 0px;
}
.navbar.navbar-light .nav-form.form-inline {
  margin-left: 190px;
  margin-right: 30px;
}
.global-search {
  display: block;
  border: none;
  width: 98% !important;
  border-radius: 20px;
  border: 0;
  background: rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.spacer-icon {
  opacity: 0;
}
.react-contextmenu {
  background: #fff;
  z-index: 9999;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: opacity 0.3s;
}
.react-contextmenu .react-contextmenu-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  min-width: 200px;
  padding: 10px 20px;
  color: #666;
  cursor: pointer;
}
.react-contextmenu .react-contextmenu-item:hover {
  background: rgba(0, 0, 0, 0.05);
}
.react-contextmenu .react-contextmenu-item.react-contextmenu-item--divider {
  padding: 0px;
  height: 1px;
  background: #eaeaea;
}
.react-contextmenu .react-contextmenu-item svg {
  margin-right: 10px;
  margin-top: -5px;
  fill: #666;
  color: #666;
}
.ml-20 {
  margin-left: 20px !important;
}
.global-search-btn {
  position: absolute;
  padding: 0px;
  right: 20px;
}
.global-search-btn:hover,
.global-search-btn:active {
  background: transparent !important;
  box-shadow: none !important;
  border: none;
}
.navbar .dropdown .dropdown-toggle .badge-count {
  position: absolute;
  background: #d00826;
  height: 15px;
  font-size: 10px;
  margin-left: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  margin-top: -10px;
  padding: 0px 5px;
  border-radius: 10px;
  right: 15px;
}
.page-header {
  margin-bottom: 20px;
}
.page-header h1 {
  font-size: 22px;
  padding-top: 10px;
}
.page-header h1 svg {
  margin-top: -5px;
  margin-right: 5px;
}
.theme-pretty {
  padding-bottom: 20px;
  height: 100vh;
}
.theme-pretty.modal {
  background: transparent;
}
.theme-pretty.modal.file-manager-modal .modal-content {
  background: transparent;
}
.theme-pretty.modal.file-manager-modal .modal-content .modal-body {
  background: #fff;
  border-radius: 20px;
}
.theme-pretty.modal.file-manager-modal .modal-content .modal-body .file-content .file-header {
  padding: 10px 20px;
  /* position: fixed; */
  width: calc(100% + 85px);
  padding-top: 20px;
  position: relative;
  z-index: 100;
  background: #eee;
  margin-top: -15px;
}
.theme-pretty.modal.file-manager-modal .modal-content .modal-body .file-content .embedded-buttons {
  margin-top: -60px;
  margin-bottom: 0px !important;
  padding-bottom: 0;
  position: relative;
  z-index: 100;
  background: #fff;
  border-top: 1px solid #eee;
  padding-top: 11px;
  margin-left: -30px;
  margin-right: -45px;
  padding-right: 20px;
}
.theme-pretty.modal.file-manager-modal .modal-content .modal-body .file-content .embedded-buttons .btn {
  margin-bottom: 0px !important;
}
.theme-pretty.modal.file-manager-modal .modal-content .modal-body .file-content .file-manager-form {
  margin-right: -56px;
}
.theme-pretty.modal.file-manager-modal .modal-content .sidebar-blur {
  height: 100vh;
  width: 100%;
  position: fixed;
  filter: blur(5px);
}
.theme-pretty.modal .file-sidebar {
  min-height: calc(100vh - 118px);
}
.theme-pretty.modal .favorites-scroller {
  top: 20px;
  background: transparent;
}
.theme-pretty .favorites-scroller {
  margin-left: -30px;
  margin-top: -20px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  background: #eee;
  margin-bottom: -20px;
}
.theme-pretty .file-manager {
  height: calc(100vh - 130px);
}
.theme-pretty .file-manager .file-manager-form {
  padding-top: 0px;
  margin: 0px -70px -20px -30px;
  background: transparent;
  border-bottom: 1px solid #eee;
  padding-bottom: 0px;
  overflow: hidden;
  opacity: 0;
  transition: max-height, opacity, padding 0.5s;
  position: relative;
  z-index: 1;
  max-height: 0;
}
.theme-pretty .file-manager .file-manager-form.uploading-files {
  max-height: 500px;
  opacity: 1;
  padding-bottom: 20px;
  padding-top: 46px;
}
.theme-pretty .file-manager .file-header {
  margin: -20px -30px;
  border-radius: 0px;
  border-top-right-radius: 20px;
  margin-right: -70px;
  border-bottom: 2px solid #ddd;
  background: rgba(0, 0, 0, 0.05);
  padding-bottom: 0px;
  z-index: 4;
}
.theme-pretty .file-manager .file-header .breadcrumb {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  border-radius: 30px;
  padding: 0px;
  overflow: hidden;
  border: 1px solid #bbb;
}
.theme-pretty .file-manager .file-header .breadcrumb li.breadcrumb-item {
  background: #eee;
  transform: skew(-30deg);
  padding: 7px 40px;
  border-right: 2px solid #bbb;
  border-top-width: 1px;
  border-bottom-width: 1px;
  margin-top: -1px;
  transition: background 0.3s;
}
.theme-pretty .file-manager .file-header .breadcrumb li.breadcrumb-item:hover {
  background: #fff;
}
.theme-pretty .file-manager .file-header .breadcrumb li.breadcrumb-item:first-child {
  margin-left: -5px;
  padding-left: 50px;
}
.theme-pretty .file-manager .file-header .breadcrumb li.breadcrumb-item::before {
  content: none;
}
.theme-pretty .file-manager .file-header .breadcrumb li.breadcrumb-item a,
.theme-pretty .file-manager .file-header .breadcrumb li.breadcrumb-item span {
  transform: skew(30deg);
  text-decoration: none !important;
}
.theme-pretty .file-manager .file-header .breadcrumb li.breadcrumb-item a {
  color: #19bcac;
  transition: color 0.3s;
}
.theme-pretty .file-manager .file-header .breadcrumb li.breadcrumb-item a:hover {
  color: #08867b;
}
.theme-pretty .file-manager .file-preview {
  margin: 10px 0px;
}
.theme-pretty .file-manager .file-preview.background-enabled {
  border-radius: 10px;
  padding: 20px;
}
.theme-pretty .file-manager .file-preview.non-folder {
  margin: 10px 0px;
  background-size: cover !important;
  background-position: center !important;
  height: 100%;
}
.theme-pretty .file-sidebar {
  max-width: 260px;
  float: left;
  width: 260px;
  background: linear-gradient(to bottom right, #08867b, #19bcac), rgba(255, 255, 255, 0.7);
  background-blend-mode: overlay;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  min-height: calc(100vh - 90px);
  overflow: hidden;
}
.theme-pretty .file-sidebar .fave .nav-link {
  background: transparent;
  padding-left: 20px;
  transition: 0.3s;
}
.theme-pretty .file-sidebar .fave .nav-link:hover {
  background: rgba(0, 0, 0, 0.05);
}
.theme-pretty .file-sidebar .fave svg {
  margin-top: -5px;
  fill: #666;
  color: #666;
  margin-right: 5px;
}
.theme-pretty .file-sidebar .fave .menu-text {
  font-size: 14px;
  padding-left: 10px;
  color: #666;
  font-weight: 500;
}
.theme-pretty .file-sidebar .fave .btn-slim {
  margin-top: -14px;
  transition: 0.3s;
  opacity: 0;
}
.theme-pretty .file-sidebar .fave:hover .btn-slim {
  opacity: 1;
}
.theme-pretty .file-sidebar .page-header {
  margin-bottom: 0px;
}
.theme-pretty .file-sidebar .page-header h1 {
  padding: 10px 30px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  color: #666;
  padding-left: 15px;
}
.theme-pretty .plan {
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
.theme-pretty .plan .custom-control.custom-switch {
  display: inline-block;
  margin-bottom: -10px;
  position: absolute;
  bottom: 26px;
  margin-left: -40px;
}
.theme-pretty .artist-img {
  height: 200px;
  width: 200px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.theme-pretty .artist-img.avatar {
  border-radius: 100%;
  background-size: cover !important;
  background-position: center !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border: 5px solid #fff;
}
.theme-pretty .artist-img.avatar.header-img {
  height: 22px;
  width: 22px;
  border: none;
}
.theme-pretty .artist-img.avatar.square {
  border-radius: 4px;
  border: none;
  float: left;
  height: 50px;
  width: 50px;
  display: inline-block;
}
.theme-pretty .artist-header-container {
  height: 230px;
  width: calc(100% + 30px);
  overflow: hidden;
  position: absolute;
  border-top-left-radius: 20px;
  margin-top: -20px;
  margin-left: -15px;
  margin-right: -60px;
  border-top-right-radius: 20px;
  border-bottom: 1px solid;
}
.theme-pretty .artist-header-container .artist-header {
  filter: blur(5px);
  width: calc(100% + 80px);
  height: 250px;
  margin-top: -10px;
  margin-left: -20px;
  background-size: cover !important;
  background-position: center !important;
  background-blend-mode: overlay;
}
.theme-pretty .artist-tile {
  height: 200px;
  width: 200px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  background-blend-mode: overlay;
}
.theme-pretty .artist-tile h3 {
  position: absolute;
  bottom: 0px;
  margin-left: 10px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  width: 200px;
}
.theme-pretty .navbar.navbar-light.bg-light {
  background: #2c3e48 !important;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 999999;
  top: 0;
  height: 60px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
.theme-pretty .navbar.navbar-light.bg-light::after {
  content: '';
  background: linear-gradient(to right, #08867b, #19bcac);
  position: fixed;
  height: 4px;
  width: 100%;
  left: 0px;
  top: 60px;
}
.theme-pretty .navbar.navbar-light.bg-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem 20px;
}
.theme-pretty .navbar.navbar-light.bg-light .btn.btn-flat {
  background: rgba(255, 255, 255, 0.1);
  height: 32px;
  border-radius: 100%;
  margin-right: 20px;
  width: 32px;
  padding: 0px !important;
}
.theme-pretty .navbar.navbar-light.bg-light .btn.btn-flat:hover {
  background: rgba(255, 255, 255, 0.05) !important;
}
.theme-pretty .navbar.navbar-light.bg-light .btn.btn-flat .artist-img.header-img {
  height: 32px;
  width: 32px;
  border: 0;
  margin-top: -1px;
  margin-left: -1px;
}
.theme-pretty .navbar.navbar-light.bg-light .global-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-pretty .navbar.navbar-light.bg-light .dropdown-toggle {
  color: rgba(255, 255, 255, 0.9);
}
.theme-pretty .navbar.navbar-light.bg-light .dropdown-toggle svg {
  fill: rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 0.9);
  width: 18px;
}
.theme-pretty .navbar.navbar-light.bg-light .main-logo {
  filter: grayscale(1) brightness(100);
  opacity: 0.8;
}
.theme-pretty .feis-page {
  background: linear-gradient(to bottom right, #08867b, #19bcac), rgba(255, 255, 255, 0.95);
  background-blend-mode: overlay;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  margin-left: 260px;
  padding-left: 20px;
  top: 60px;
  margin-bottom: 0px;
  right: 0px;
  margin-right: 0;
  width: calc(100% - 260px);
  left: initial;
  height: calc(100vh - 60px);
  transition-duration: 0.5s;
  bottom: 0px;
  border-radius: 0px;
  background: transparent;
  box-shadow: none;
  z-index: 0;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.theme-pretty .feis-page .grades-table-view .table-container {
  overflow-x: scroll;
}
.theme-pretty .feis-page .grades-table-view th {
  min-width: 200px;
}
.theme-pretty .feis-page .grades-table-view th .btn {
  position: absolute;
  right: 14px;
  top: 13px;
  width: 40px;
  padding-left: 10px;
}
.theme-pretty .feis-page .grades-table-view .btn-flat.delete-row {
  position: absolute;
  right: 14px;
  top: 13px;
  width: 40px;
  padding-left: 10px;
}
.theme-pretty .feis-page .profile-page .profile-header {
  text-align: center;
  background: #19bcac;
  padding-top: 40px;
  padding-bottom: 40px;
}
.theme-pretty .feis-page .profile-page .profile-header .artist-img.avatar {
  margin: auto;
  margin-bottom: 20px;
}
.theme-pretty .feis-page .profile-page .profile-header h2 {
  color: rgba(255, 255, 255, 0.8);
}
.theme-pretty .feis-page .ScrollbarsCustom.re .ScrollbarsCustom-Content {
  padding: 0px !important;
}
.theme-pretty .feis-page.full-width {
  width: 100%;
  margin-left: 0px;
  padding-left: 10px;
}
.theme-pretty .feis-page.judge-view {
  width: calc(100% - 70px);
  margin-left: 70px;
  padding-bottom: 100px;
}
.theme-pretty .feis-page label {
  font-size: 0.8rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 15px;
}
.theme-pretty .feis-page .text-hint {
  font-size: 12px;
  color: #999;
  margin-top: 20px;
}
.theme-pretty .feis-page .text-hint.update-right {
  margin-top: 15px;
  padding-right: 30px;
}
.theme-pretty .feis-page .leaderboard-container {
  display: inline-block;
  height: calc(100vh - 110px);
  padding-left: 0px;
  width: 100px;
  float: left;
}
.theme-pretty .feis-page .leaderboard-container .ScrollbarsCustom-Content {
  padding-left: 15px !important;
}
.theme-pretty .feis-page .leaderboard-container .sm-dancer-number {
  text-align: center;
  font-weight: 600;
}
.theme-pretty .feis-page .leaderboard-container .leader-item {
  width: 64px;
  margin-bottom: 10px;
}
.theme-pretty .feis-page .leaderboard-container .leader-item .badge-count {
  position: absolute;
  margin-left: 50px;
  height: 22px;
  text-align: center;
  background: #d40825;
  color: #fff;
  font-size: 14px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
  margin-top: -10px;
  padding: 0px 5px;
  border-radius: 10px;
  right: 15px;
}
.theme-pretty .feis-page .leaderboard-container .leader-item .badge-count.secondary-badge {
  margin-left: -10px;
  margin-top: 50px;
  background: #ff784f;
}
.theme-pretty .feis-page .leaderboard-container .leader-item .leader-image {
  height: 64px;
  width: 64px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  background-size: cover !important;
  background-position: center !important;
  border: 4px solid transparent;
}
.theme-pretty .feis-page .leaderboard-container .leader-item .leader-image.active {
  border: 4px solid #19bcac;
}
.theme-pretty .feis-page .video-container-wrapper {
  padding: 0px;
  box-sizing: border-box;
  resize: horizontal;
  /* border: 1px dashed; */
  /* overflow: auto; */
  max-width: 100%;
  height: calc(100vh - 110px);
  width: calc(100% - 560px);
  margin-left: 20px;
  display: inline-block;
  float: none;
}
.theme-pretty .feis-page .video-container-wrapper .video-container {
  width: 100%;
  position: relative;
}
.theme-pretty .feis-page .video-container-wrapper .video-container .video-overlay-left {
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0%);
  position: absolute;
  color: rgba(255, 255, 255, 0.9);
  left: 20px;
  top: 15px;
}
.theme-pretty .feis-page .video-container-wrapper .video-container video {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.theme-pretty .feis-page .video-container-wrapper .video-container video:focus {
  outline: none !important;
}
.theme-pretty .feis-page .scorecard-container {
  display: inline-block;
  width: 420px;
  margin-left: 20px;
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  float: right;
}
.theme-pretty .feis-page .theme-pretty .favorites-scroller {
  margin-left: 0px;
  margin-top: -94px !important;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  background: #eee;
  margin-bottom: -20px;
  width: 260px;
  height: calc(100vh - 90px);
  top: 20px !important;
}
.theme-pretty .feis-page .theme-pretty .favorites-scroller .ScrollbarsCustom-Wrapper {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.theme-pretty .feis-page.player-open {
  height: calc(100vh - 170px);
  min-height: calc(100vh - 170px);
}
.theme-pretty .feis-page.player-open .theme-pretty .favorites-scroller {
  height: calc(100vh - 170px);
}
.theme-pretty .sidebar {
  padding-top: 55px;
}
.theme-pretty .sidebar.judge-view .sidebar-bottom {
  display: none;
}
.theme-pretty .sidebar.judge-view .sidebar-bottom-alt {
  display: block;
}
.theme-pretty .sidebar .sidebar-bottom-alt {
  display: none;
}
.theme-pretty .sidebar .sidebar-bottom,
.theme-pretty .sidebar .sidebar-bottom-alt {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.8125rem;
  letter-spacing: 0.06rem;
}
.theme-pretty .sidebar .sidebar-bottom a,
.theme-pretty .sidebar .sidebar-bottom-alt a {
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.3s;
}
.theme-pretty .sidebar .sidebar-bottom a:hover,
.theme-pretty .sidebar .sidebar-bottom-alt a:hover {
  text-decoration: none;
  color: #19bcac;
}
.theme-pretty .sidebar .feis-sidebar-scroll {
  height: calc(100vh - 80px);
}
.theme-pretty .sidebar .feis-sidebar-scroll .ScrollbarsCustom-Content {
  padding-bottom: 120px !important;
  position: relative;
}
.theme-pretty .sidebar .separator {
  background: rgba(0, 0, 0, 0.05);
}
.theme-pretty .sidebar .nav-item {
  width: 260px;
}
.theme-pretty .sidebar .nav-item:first-child {
  padding-top: 20px;
}
.theme-pretty .sidebar .nav-item .menu-title {
  color: #aaa;
}
.theme-pretty .sidebar .nav-item .nav-link {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 2px;
}
.theme-pretty .sidebar .nav-item .nav-link::before {
  content: '';
  position: absolute;
  height: 54px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 260px;
  margin-top: -15px;
  transform: scale(0);
  transition: all 0.3s;
  opacity: 0;
}
.theme-pretty .sidebar .nav-item .nav-link::after {
  background: #ff784f;
  content: '';
  position: absolute;
  width: 2px;
  height: 54px;
  margin-top: -15px;
  left: 0;
  transform: translate(-10px, 0px);
  transition: all 0.2s;
}
.theme-pretty .sidebar .nav-item .nav-link svg {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
}
.theme-pretty .sidebar .nav-item .nav-link:hover::before {
  transform: scale(1);
  opacity: 1;
}
.theme-pretty .sidebar .nav-item.active .nav-link {
  background: rgba(0, 0, 0, 0.05) !important;
  color: #19bcac;
}
.theme-pretty .sidebar .nav-item.active .nav-link svg {
  fill: #19bcac;
  color: #19bcac;
}
.theme-light {
  background: #f7f7f7;
  color: #666;
}
.theme-light .favorites-scroller {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.theme-light .file-sidebar {
  max-width: 260px;
  float: left;
  width: 260px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  min-height: calc(100vh - 90px);
  overflow: hidden;
}
.theme-light .file-sidebar .fave .nav-link {
  background: transparent;
  padding-left: 20px;
}
.theme-light .file-sidebar .fave .nav-link:hover {
  background: rgba(0, 0, 0, 0.05);
}
.theme-light .file-sidebar .fave svg {
  margin-top: -5px;
  fill: #666;
  color: #666;
  margin-right: 5px;
}
.theme-light .file-sidebar .fave .menu-text {
  font-size: 14px;
  padding-left: 10px;
  color: #666;
  font-weight: 500;
}
.theme-light .file-sidebar .fave .btn-slim {
  margin-top: -14px;
  display: none;
}
.theme-light .file-sidebar .fave:hover .btn-slim {
  display: block;
}
.theme-light .file-sidebar .page-header {
  margin-bottom: 0px;
}
.theme-light .file-sidebar .page-header h1 {
  padding: 10px 30px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  color: #666;
  padding-left: 15px;
}
.theme-light .navbar.navbar-light {
  background: #fff !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}
.theme-light .global-search {
  background: rgba(0, 0, 0, 0.1);
}
.theme-light .global-search-btn {
  position: absolute;
  padding: 0px;
  right: 20px;
}
.theme-light .global-search-btn:hover,
.theme-light .global-search-btn:active {
  background: transparent !important;
  box-shadow: none !important;
  border: none;
}
.theme-light .navbar .dropdown {
  padding-left: 20px;
}
.theme-light .navbar .dropdown .dropdown-toggle {
  padding-right: 10px;
}
.theme-light .sidebar.judge-view {
  width: 70px;
}
.theme-light .sidebar .separator {
  background: rgba(0, 0, 0, 0.1);
}
.theme-light .sidebar .nav-item .menu-title {
  color: #aaa;
}
.theme-light .sidebar .nav-item.active .nav-link {
  background: rgba(0, 0, 0, 0.05) !important;
}
.theme-light .sidebar .nav-item .nav-link {
  color: #606060;
}
.theme-light .name-circle {
  background: linear-gradient(to right, #08867b, #19bcac);
  color: #fff;
}
.theme-light .dropdown-menu {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
}
.theme-light .dropdown-menu a {
  padding: 0.8rem 0rem;
}
.feis-page {
  margin-left: 270px;
  min-height: calc(100vh - 90px);
  margin-top: 0px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 20px;
}
.feis-page .feis-page-scroller.flat {
  background: transparent !important;
  box-shadow: none !important;
}
.feis-page .feis-page-scroller.no-padding-scroller .ScrollbarsCustom-Content {
  padding: 0px !important;
}
.feis-page .feis-page-scroller .ScrollbarsCustom-Content {
  padding: 20px 30px !important;
}
.feis-page.player-open .feis-page-scroller {
  height: calc(100vh - 170px) !important;
}
.feis-page .row-title {
  font-weight: 600;
}
.feis-page .row-name {
  font-size: 22px;
  padding: 10px;
  display: block;
}
.feis-page .row-name.article-row {
  font-weight: 600;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.feis-page .row-name.article-row .row-content {
  font-weight: 400;
  white-space: pre;
  font-style: italic;
}
.feis-page .row-name.article-row .row-content::after {
  content: " ";
  position: absolute;
  background: linear-gradient(to right, transparent, #fff);
  width: 100px;
  height: 100px;
  right: 20px;
}
.sidebar {
  float: none;
  width: 260px;
  position: fixed;
}
.sidebar.judge-view {
  width: 70px;
}
.sidebar .separator {
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 0px 24px;
}
.sidebar .closed {
  width: 0px;
}
.sidebar .nav-item .menu-title {
  margin-left: 24px;
  font-size: 14px;
  font-weight: 700;
  color: #aaa;
  text-transform: uppercase;
  padding: 10px;
  padding-left: 0px;
}
.sidebar .nav-item.active .nav-link {
  background: rgba(0, 0, 0, 0.05) !important;
}
.sidebar .nav-item .nav-link {
  padding: 10px;
  color: #606060;
  padding-left: 0px;
  padding: 15px 10px 15px 0;
}
.sidebar .nav-item .nav-link svg {
  margin: 0px 24px;
  margin-top: -4px;
  margin-right: 20px;
}
.name-circle {
  float: left;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background: #fff;
  color: #c06;
  padding-top: 3px;
  text-transform: capitalize;
  position: relative;
  margin-left: -40px;
  margin-top: -3px;
}
.menu-icon {
  line-height: 35px;
  float: left;
  margin: 3px 15px;
}
.dropdown-menu {
  margin: 0;
  right: 10px;
  left: initial;
  margin-top: 10px;
  width: 360px;
  border: 0;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  top: 60px;
  background: #fafafa;
}
.dropdown-menu .dropdown-footer {
  text-align: center;
  display: block;
  color: #666;
  font-size: 12px;
  font-weight: 600;
}
.dropdown-menu a {
  padding: 0.8rem 0rem;
}
.btn.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.btn:not(.dropdown-toggle) {
  padding: 8px 30px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgb(0 0 0%);
  border: none;
  text-transform: capitalize !important;
  transition: background 0.5s linear;
  -moz-transition: background 0.5s linear;
  /* Firefox 4 */
  -webkit-transition: background 0.5s linear;
  /* Safari and Chrome */
  -o-transition: background 0.5s linear;
  /* Opera */
  -ms-transition: background 0.5s linear;
  /* Explorer 10 */
  font-weight: 400;
  letter-spacing: 1px;
  transition: all 0.3s;
}
.btn:not(.dropdown-toggle).btn-secondary {
  background: #ff784f;
}
.btn:not(.dropdown-toggle).btn-secondary.topZ {
  position: relative;
  z-index: 999;
}
.btn:not(.dropdown-toggle).btn-round {
  padding: 8px !important;
  border-radius: 100%;
  margin-right: 20px;
}
.btn:not(.dropdown-toggle).btn-round svg {
  margin-top: -2px;
  margin-right: 0px;
}
.btn:not(.dropdown-toggle).btn-action {
  position: fixed;
  bottom: 30px;
  right: 25px;
  height: 64px;
  width: 64px;
  border-radius: 100%;
  padding: 10px;
  padding-top: 7px;
  transition: all 0.5s;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}
.btn:not(.dropdown-toggle).btn-transparent {
  background: transparent;
  color: #aaa;
}
.btn:not(.dropdown-toggle).btn-transparent svg {
  fill: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  margin-left: -10px;
  margin-right: 5px;
  margin-top: -3px;
}
.btn:not(.dropdown-toggle).btn-flat {
  box-shadow: none;
}
.btn:not(.dropdown-toggle).btn-flat.btn-circle {
  padding: 8px;
  height: 38px;
}
.btn:not(.dropdown-toggle).btn-flat.raised {
  background: #eee;
}
.btn:not(.dropdown-toggle).btn-flat:hover,
.btn:not(.dropdown-toggle).btn-flat:active {
  box-shadow: none !important;
  border: none;
}
.btn:not(.dropdown-toggle).btn-flat.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.btn:not(.dropdown-toggle).btn-danger:not(.btn-action) svg {
  fill: rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 0.9);
  margin-left: -10px;
  margin-right: 5px;
  margin-top: -3px;
}
.btn:not(.dropdown-toggle).btn-primary {
  background: #19bcac;
}
.btn:not(.dropdown-toggle).btn-primary:not(.btn-action) svg {
  fill: rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 0.9);
  margin-left: -10px;
  margin-right: 5px;
  margin-top: -3px;
}
.btn:not(.dropdown-toggle).btn-primary:hover {
  background: #08867b;
}
.btn:not(.dropdown-toggle).btn-link {
  box-shadow: none!important;
  text-transform: initial !important;
  padding: 0px !important;
  color: #19bcac;
  transition: all 0.3s;
}
.btn:not(.dropdown-toggle).btn-link svg {
  fill: #19bcac;
  color: #19bcac;
  margin-top: -3px;
  transition: all 0.3s;
}
.btn:not(.dropdown-toggle).btn-link:hover {
  text-decoration: none;
  color: #08867b;
}
.btn:not(.dropdown-toggle).btn-link:hover svg {
  fill: #08867b;
  color: #08867b;
}
.btn:not(.dropdown-toggle):hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}
.auth-page {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom right, #08867b, #19bcac);
  padding-bottom: 100px;
}
.auth-page .auth-logo {
  position: relative;
  height: 220px;
  margin: auto;
  padding: 40px 20px;
  display: block;
}
.auth-page .login-form {
  max-width: calc(100% - 40px);
  width: 500px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  margin: auto;
}
.auth-page .login-form .ScrollbarsCustom-Content {
  padding: 10px 30px !important;
  padding-bottom: 40px !important;
}
.dark-theme.modal {
  background: rgba(0, 0, 0, 0.4);
}
.dark-theme.modal .modal-content {
  background: #1f2122;
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme {
  background: #18191a;
  color: rgba(255, 255, 255, 0.8);
  height: 100%;
}
.feis-app.dark-theme .square-tile {
  background: #303335;
}
.feis-app.dark-theme .square-tile .tile-content {
  background: #303335;
}
.feis-app.dark-theme .square-tile .tile-content h5 {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .single-export {
  background: #494c4f;
}
.feis-app.dark-theme .single-export h4 {
  color: rgba(255, 255, 255, 0.6) !important;
}
.feis-app.dark-theme .btn.btn-link {
  color: #08867b !important;
}
.feis-app.dark-theme .notification-header h5 {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .note-container {
  background: #494c4f;
}
.feis-app.dark-theme .note-container .note-content-container {
  color: rgba(230, 230, 230, 0.8);
}
.feis-app.dark-theme .profile-right .info-area {
  box-shadow: 0px 2px 4px rgb(0 0 0%);
}
.feis-app.dark-theme .profile-right .info-area li {
  border-bottom: 1px solid #3d3f42;
}
.feis-app.dark-theme .profile-right .info-area li label b {
  color: #cacaca;
}
.feis-app.dark-theme .circle-tile .tile-content h5 {
  color: rgba(255, 255, 255, 0.5);
}
.feis-app.dark-theme .event-container .btn-flat.raised {
  background: #242627 !important;
}
.feis-app.dark-theme .pie-container h5 {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .round-tile {
  background: #444;
}
.feis-app.dark-theme .highcharts-container {
  border: 1px solid #444;
  border-radius: 5px;
}
.feis-app.dark-theme .highcharts-title {
  fill: #ccc !important;
  color: #ccc !important;
}
.feis-app.dark-theme .highcharts-grid.highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #3a3a3a;
}
.feis-app.dark-theme .highcharts-axis-line {
  stroke: #3a3a3a;
}
.feis-app.dark-theme .highcharts-legend-item.highcharts-line-series text {
  color: #ccc !important;
  fill: #ccc !important;
}
.feis-app.dark-theme .highcharts-axis-labels.highcharts-xaxis-labels text {
  color: #ccc !important;
  fill: #ccc !important;
}
.feis-app.dark-theme .highcharts-axis-labels.highcharts-yaxis-labels text {
  color: #ccc !important;
  fill: #ccc !important;
}
.feis-app.dark-theme .object-picker-input .object-picker-menu-item.dropdown-item:hover,
.feis-app.dark-theme .video-upload {
  background: #222;
}
.feis-app.dark-theme .no-notes {
  color: rgba(204, 204, 204, 0.8);
}
.feis-app.dark-theme td .form-control {
  background: transparent;
}
.feis-app.dark-theme .footer a {
  color: rgba(255, 255, 255, 0.7);
}
.feis-app.dark-theme .footer a:hover {
  text-decoration: none;
  color: #19bcac;
}
.feis-app.dark-theme .credit-card-container .credit-card {
  background: #3d3f42;
}
.feis-app.dark-theme .plan {
  background: #303335;
}
.feis-app.dark-theme .plan .row-name.article-row .row-content::after {
  background: linear-gradient(to right, transparent, #303335);
}
.feis-app.dark-theme .onboarding-steps .onboarding-step,
.feis-app.dark-theme .event-steps .onboarding-step,
.feis-app.dark-theme .onboarding-steps .event-step,
.feis-app.dark-theme .event-steps .event-step {
  background: transparent;
  border-color: #494c4f !important;
}
.feis-app.dark-theme .onboarding-steps .onboarding-step.active,
.feis-app.dark-theme .event-steps .onboarding-step.active,
.feis-app.dark-theme .onboarding-steps .event-step.active,
.feis-app.dark-theme .event-steps .event-step.active {
  border-color: #08867b !important;
  color: #08867b;
  font-weight: 600;
}
.feis-app.dark-theme .onboarding-steps .onboarding-step.active::after,
.feis-app.dark-theme .event-steps .onboarding-step.active::after,
.feis-app.dark-theme .onboarding-steps .event-step.active::after,
.feis-app.dark-theme .event-steps .event-step.active::after {
  border-color: #08867b;
}
.feis-app.dark-theme .onboarding-steps .onboarding-step.active-right::after,
.feis-app.dark-theme .event-steps .onboarding-step.active-right::after,
.feis-app.dark-theme .onboarding-steps .event-step.active-right::after,
.feis-app.dark-theme .event-steps .event-step.active-right::after {
  border-color: #08867b;
}
.feis-app.dark-theme .onboarding-steps .onboarding-step:nth-child(1),
.feis-app.dark-theme .event-steps .onboarding-step:nth-child(1),
.feis-app.dark-theme .onboarding-steps .event-step:nth-child(1),
.feis-app.dark-theme .event-steps .event-step:nth-child(1) {
  border-left: 2px solid;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.feis-app.dark-theme .onboarding-steps .onboarding-step:nth-child(1).active,
.feis-app.dark-theme .event-steps .onboarding-step:nth-child(1).active,
.feis-app.dark-theme .onboarding-steps .event-step:nth-child(1).active,
.feis-app.dark-theme .event-steps .event-step:nth-child(1).active {
  border-color: #08867b !important;
}
.feis-app.dark-theme .onboarding-steps .onboarding-step::after,
.feis-app.dark-theme .event-steps .onboarding-step::after,
.feis-app.dark-theme .onboarding-steps .event-step::after,
.feis-app.dark-theme .event-steps .event-step::after {
  background: transparent;
  margin-top: -5px;
  border-color: #494c4f;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light {
  background: #303335 !important;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item {
  width: calc(100% - 0px);
  margin: 0px !important;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item .menu-title {
  color: #aaa;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7) !important;
  position: relative;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item .nav-link::after {
  content: '';
  position: absolute;
  height: 54px !important;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  width: calc(100% - 20px);
  margin-top: -15px;
  transform: scale(0);
  transition: all 0.3s;
  opacity: 0;
  left: 10px;
  right: 10px;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item .nav-link svg {
  fill: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);
  margin: -4px 20px 0 24px;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item .nav-link:hover {
  background: transparent !important;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item .nav-link:hover::before {
  content: none !important;
  display: none;
  transform: scale(0) !important;
  opacity: 0 !important;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item .nav-link:hover::after {
  transform: scale(1);
  opacity: 1;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item.active .nav-link {
  color: #19bcac !important;
}
.feis-app.dark-theme .navbar.navbar-light.bg-light .nav-item.active .nav-link svg {
  fill: #19bcac;
  color: #19bcac;
}
.feis-app.dark-theme .bmOFCG {
  background: #3d3f42;
}
.feis-app.dark-theme .competition-tile,
.feis-app.dark-theme .type-tile {
  background: #494c4f;
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .competition-tile:hover,
.feis-app.dark-theme .type-tile:hover {
  color: #19bcac;
}
.feis-app.dark-theme .feis-page {
  height: 100%;
  background: #18191a;
  min-height: calc(100vh - 60px);
}
.feis-app.dark-theme .grid-tile {
  background: #494c4f;
}
.feis-app.dark-theme .grid-tile:hover svg {
  fill: #19bcac;
  color: #19bcac;
}
.feis-app.dark-theme .grid-tile:hover h3 {
  color: #19bcac;
}
.feis-app.dark-theme .grid-tile svg {
  fill: rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .grid-tile h3 {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .divider {
  background: #18191a;
}
.feis-app.dark-theme .row-details .detail-value {
  color: rgba(255, 255, 255, 0.7);
}
.feis-app.dark-theme .dropdown-divider {
  border-color: #494c4f;
}
.feis-app.dark-theme .dropdown .dropdown-menu {
  background: #3d3f42;
}
.feis-app.dark-theme .dropdown .dropdown-menu a,
.feis-app.dark-theme .dropdown .dropdown-menu .dropdown-item {
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s;
}
.feis-app.dark-theme .dropdown .dropdown-menu a:hover,
.feis-app.dark-theme .dropdown .dropdown-menu .dropdown-item:hover {
  background: #494c4f;
}
.feis-app.dark-theme .dropdown .dropdown-menu a svg,
.feis-app.dark-theme .dropdown .dropdown-menu .dropdown-item svg {
  fill: rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .dropdown .dropdown-menu .note-container {
  background: #494c4f;
}
.feis-app.dark-theme .dropdown .dropdown-menu .note-container .note-content-container {
  color: rgba(230, 230, 230, 0.8);
}
.feis-app.dark-theme ::-webkit-input-placeholder,
.feis-app.dark-theme :-ms-input-placeholder,
.feis-app.dark-theme ::placeholder {
  /* Edge */
  color: rgba(255, 255, 255, 0.8) !important;
}
.feis-app.dark-theme .page-header {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .page-header svg {
  fill: rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .object-picker-container-top .object-picker-container {
  background: #3d3f42;
}
.feis-app.dark-theme .object-picker-container-top .object-picker-container input {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .divider {
  background: #494c4f;
}
.feis-app.dark-theme .form-buttons {
  border-color: #3d3f42;
}
.feis-app.dark-theme .tab-highlight-track {
  background: #3d3f42;
}
.feis-app.dark-theme .table {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .table th,
.feis-app.dark-theme .table thead th,
.feis-app.dark-theme .table td {
  border-color: #494c4f !important;
}
.feis-app.dark-theme .tab-row a {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme label {
  color: rgba(255, 255, 255, 0.8) !important;
}
.feis-app.dark-theme .btn {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .btn.btn-flat {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .btn.btn-flat svg {
  fill: rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme input,
.feis-app.dark-theme .form-control {
  color: rgba(255, 255, 255, 0.8);
  background: #3d3f42;
}
.feis-app.dark-theme .form-control:focus {
  background: #3d3f42;
}
.feis-app.dark-theme .elevated {
  background: #303335;
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .sidebar {
  background: #18191a;
}
.feis-app.dark-theme .sidebar .sidebar-bottom a {
  color: rgba(204, 204, 204, 0.8);
}
.feis-app.dark-theme .sidebar .sidebar-bottom a:hover {
  color: #19bcac;
}
.feis-app.dark-theme .sidebar .separator {
  background: #303335;
}
.feis-app.dark-theme .sidebar .nav-item.active .nav-link {
  color: #19bcac;
  background: rgba(38, 38, 38, 0.8) !important;
}
.feis-app.dark-theme .sidebar .nav-item.active .nav-link svg {
  fill: #19bcac;
  color: #19bcac;
}
.feis-app.dark-theme .sidebar .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .sidebar .nav-item .nav-link svg {
  fill: rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.feis-app.dark-theme .sidebar .nav-item .nav-link:before,
.feis-app.dark-theme .sidebar .nav-item .nav-link:after {
  background: rgba(33, 33, 33, 0.8);
  z-index: -1;
}
